import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import * as actions from '../actions';
import axios from 'axios';

const Banner = ({ auth }) => {

    const [leaderboardRank, setLeaderboardRank] = useState([]); 

    useEffect(() => {
        const fetchLeaderboardRank = async () => {
            const response = await axios.get("/api/get/leaderboard-rank", {
                validateStatus: function (status) {
                    return status < 500; // Resolve only if the status code is less than 500
                }
            });
            setLeaderboardRank(response.data);
            
        }
        if (auth) {
            fetchLeaderboardRank();
        }
    }, [auth])

    const renderBanner = () => {
        //console.log('registered: ', registeredUser);
        //console.log('auth.account_type:', auth?.account_type);

        if ( auth?.account_type !== undefined && auth?.account_type !== 'owner' && auth?.account_type !== 'admin' && auth?.account_type !== 'researcher' ) {
            return <>
                <div className="d-lg-block d-none">
                    <div className="row" style={{position:"fixed", backgroundColor:"rgb(41, 25, 74)", top:"55px", marginBottom:"20px", padding:"4px", paddingTop:"4px", color:"#ffffff", width:"100%", height:"25px", zIndex:"10"}}>
                        <div className="col-1"></div>
                        <div className="col-9" style={{ fontSize:"0.7em"}}>
                            NEW Annual Pass NFT (0.05 ETH) mints Oct 20. <a href="https://heymint.xyz/future-mints-annual" target="_BLANK" rel="noreferrer" style={{color:"#ffffff"}}>Signup for Allow List raffle.</a>
                        </div>
                        <div className="col-2" style={{ fontSize:"0.7em", textAlign:"right", paddingRight:"40px"}}>
                            <Link to="/leaderboard" style={{color:"#ffffff"}}>{leaderboardRank?.points !== undefined ? leaderboardRank?.points : 0} Point{leaderboardRank?.points === 1 ? '' : 's'} | Rank: {leaderboardRank?.rank !== undefined ? leaderboardRank?.rank : 'N/A'}</Link>
                        </div>
                    </div>
                    <div className="row" style={{marginTop:"80px", paddingBottom:"20px"}}></div>
                </div>
                <div className="d-block d-lg-none">
                    <div className="row" style={{position:"fixed", backgroundColor:"rgb(41, 25, 74)", top:"56px", padding:"4px", paddingTop:"4px", color:"#ffffff", width:"100%", height:"25px", zIndex:"10"}}>
                        <div className="col-6" style={{ fontSize:"0.7em"}}>
                            <a href="https://heymint.xyz/future-mints-annual" target="_BLANK" rel="noreferrer" style={{color:"#ffffff"}}>Annual Pass mints Oct 20</a>
                        </div>
                        <div className="col-6" style={{ fontSize:"0.7em", textAlign:"right", paddingRight:"20px"}}>
                            <Link to="/leaderboard" style={{color:"#ffffff"}}>{leaderboardRank?.points !== undefined ? leaderboardRank?.points : 0} Point{leaderboardRank?.points === 1 ? '' : 's'} | Rank: {leaderboardRank?.rank !== undefined ? leaderboardRank?.rank : 'N/A'}</Link>
                        </div>
                    </div>
                    <div className="row" style={{marginTop:"80px", paddingBottom:"20px"}}></div>
                </div>
            </>;
        }
        else {
            return <>
                <div className="d-lg-block d-none">
                    <div className="row" style={{position:"fixed", backgroundColor:"rgb(41, 25, 74)", top:"55px",  padding:"4px", paddingTop:"4px", color:"#ffffff", width:"100%", height:"25px", zIndex:"10"}}>
                        <div className="col-1"></div>
                        <div className="col-9" style={{ fontSize:"0.7em"}}>
                            NEW Annual Pass NFT (0.05 ETH) mints Oct 20. <a href="https://heymint.xyz/future-mints-annual" target="_BLANK" rel="noreferrer" style={{color:"#ffffff"}}>Signup for Allow List raffle.</a>
                        </div>
                        <div className="col-2" style={{ fontSize:"0.7em", textAlign:"right", paddingRight:"40px"}}>
                            <Link to="/leaderboard" style={{color:"#ffffff", textDecoration:"none"}}>Leaderboard</Link>
                        </div>
                    </div>
                    <div className="row" style={{marginTop:"80px", paddingBottom:"20px"}}></div>
                </div>

                <div className="d-block d-lg-none">
                    <div className="row" style={{position:"fixed", backgroundColor:"rgb(41, 25, 74)", top:"56px", padding:"4px", paddingTop:"4px", color:"#ffffff", width:"100%", height:"25px", zIndex:"10"}}>
                        <div className="col-6" style={{ fontSize:"0.7em"}}>
                            <a href="https://heymint.xyz/future-mints-annual" target="_BLANK" rel="noreferrer" style={{color:"#ffffff"}}>Annual Pass mints Oct 20</a>
                        </div>
                        <div className="col-6" style={{ fontSize:"0.7em", textAlign:"right", paddingRight:"20px"}}>
                            <Link to="/leaderboard" style={{color:"#ffffff"}}>Leaderboard</Link>
                        </div>
                    </div>
                    <div className="row" style={{marginTop:"80px", paddingBottom:"20px"}}></div>
                </div>
            </>;
        }
    }

    return (
        <div>
            {renderBanner()}
        </div>
        
        //<div style={{ backgroundColor:"#ffffff", marginTop:"50px"}}>TRUE</div>
    );
}

function mapStateToProps({ auth }) {
    return { auth };
}

export default connect(mapStateToProps, actions)(Banner);
