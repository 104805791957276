import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import * as actions from '../../actions';
import axios from 'axios';

// header components
import Nav from "react-bootstrap/Nav";
import { Link } from 'react-router-dom';
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import WalletButton from '../WalletButton';
import NavLink from 'react-bootstrap/esm/NavLink';

import RenderMobileBadges from '../header/RenderMobileBadges';
import SearchForm from './SearchFormNew';
import SearchFormMobile from './SearchFormMobileNew';

const Header = ({ auth, wallet, registeredUser, alerts }) => {    

    const accountType   = registeredUser.account_type;
    const alertsCount   = alerts;       
    const username      = registeredUser.username;
    
    const alertsIcon = (alerts) => {            
        if ( alerts > 0 ) {
            return (
                <Nav style={{padding:"8px 16px"}}>
                    <Link to="/alerts">                                                                     
                        <span className="position-relative">
                            <svg xmlns="http://www.w3.org/2000/svg" 
                                width="20" 
                                height="20" 
                                fill="#ffffff" 
                                className="bi bi-bell-fill" 
                                viewBox="0 0 16 16"
                            >
                                    <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zm.995-14.901a1 1 0 1 0-1.99 0A5.002 5.002 0 0 0 3 6c0 1.098-.5 6-2 7h14c-1.5-1-2-5.902-2-7 0-2.42-1.72-4.44-4.005-4.901z"/>
                                </svg>                            
                            <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill" style={{backgroundColor:"#7945E1", color:"#ffffff", fontSize:"0.5em", padding:"4px", border:"2px solid #120B21"}}>
                                {alerts}
                                <span className="visually-hidden">unread notifications</span>
                            </span>
                        </span>
                    </Link>
                </Nav> 
            )
        }
        else if ( alerts === false ) {
            return (
                <Nav style={{padding:"8px 16px"}}>
                    <Link to="/alerts">                             
                            <svg    xmlns="http://www.w3.org/2000/svg" 
                                    width="20" 
                                    height="20" 
                                    fill="#ffffff" 
                                    className="bi bi-bell" 
                                    viewBox="0 0 16 16"
                                >
                                <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zM8 1.918l-.797.161A4.002 4.002 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4.002 4.002 0 0 0-3.203-3.92L8 1.917zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5.002 5.002 0 0 1 13 6c0 .88.32 4.2 1.22 6z"/>
                            </svg>                                                                    
                    </Link>
                </Nav> 
            )
        }

        return '';
    }

    const alertsMobile = (alerts) => {            
        if ( alerts > 0 ) {
            return (                    
                    <Nav className="ms-auto" style={{padding:"8px 8px"}}>
                        <Link to="/alerts"> 
                            <span className="position-relative">
                                <svg xmlns="http://www.w3.org/2000/svg" 
                                    width="20" 
                                    height="20" 
                                    fill="#ffffff" 
                                    className="bi bi-bell-fill" 
                                    viewBox="0 0 16 16"
                                >
                                        <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zm.995-14.901a1 1 0 1 0-1.99 0A5.002 5.002 0 0 0 3 6c0 1.098-.5 6-2 7h14c-1.5-1-2-5.902-2-7 0-2.42-1.72-4.44-4.005-4.901z"/>
                                    </svg>                            
                                <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill" style={{backgroundColor:"#7945E1", color:"#ffffff", fontSize:"0.5em", padding:"4px", border:"2px solid #120B21"}}>
                                    {alerts}
                                    <span className="visually-hidden">unread notifications</span>
                                </span>
                            </span>
                        </Link>
                    </Nav> 
            )
        }
        else if ( alerts === false ) {
            return (
                    <Nav style={{padding:"8px 8px"}}>
                        <Link to="/alerts">                                 
                                <svg    xmlns="http://www.w3.org/2000/svg" 
                                        width="20" 
                                        height="20" 
                                        fill="#ffffff" 
                                        className="bi bi-bell" 
                                        viewBox="0 0 16 16"
                                    >
                                    <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zM8 1.918l-.797.161A4.002 4.002 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4.002 4.002 0 0 0-3.203-3.92L8 1.917zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5.002 5.002 0 0 1 13 6c0 .88.32 4.2 1.22 6z"/>
                                </svg>                                                                                                    
                        </Link>
                    </Nav> 
            )
        }

        return '';
    }

    const upgradeMobile = () => {        
        return <>
            <Navbar.Toggle bsPrefix="nothing" as="span" label="none" >
                <Link to="/signup" className="nav-link" style={{color:"#ffffff", textDecoration:"none"}} activeStyle={{ backgroundColor: "transparent"}}>
                        Upgrade
                </Link>
            </Navbar.Toggle>                            
        </>;
    }

    const signupMobile = () => {
        return <>
            <Navbar.Toggle bsPrefix="nothing" as="span" label="none" >
                <Link to="/signup" className="nav-link" style={{color:"#ffffff", textDecoration:"none"}} activeStyle={{ backgroundColor: "transparent"}}>
                        Signup
                </Link>
            </Navbar.Toggle>                            
        </>;
    }

    const renderSearchDesktop = () => {
        const initialValues = {};
        const url = "/all-nfts?warnings=true";

        return <>
                        
            <div className="row">
                <SearchForm 
                    initialValues={initialValues}
                    onSubmit={
                        async (values) => {
                            try {
                                //const { url } = await fetch('/s3Url').then(res => res.json())
                                //console.log(url)
                                // https://axios-http.com/docs/instance
                                // axios#post(url[, data[, config]])
                                //console.log(values);
                                
                                if ( values.collectionId && values.collectionId !== 'undefined' ) {
                                    window.location.assign(`/collection/${values.collectionId}`);
                                }                                

                                else if (values.searchBar){

                                    let paramsNew = url;

                                    if ( paramsNew != (url + '?' ) ) {
                                        paramsNew += '&';
                                    }
                                    paramsNew += 'searchBar=' + values.search;

                                    window.location.assign(paramsNew);
                                }                                     
                                                                
                                
                            } catch (error) {
                                // This should be visible in FS and (hopefully) alert FS of the issue
                                console.error(error.response)
                            }
                        }
                    }
                />
            </div>
            
        </>;
    }

    const renderSearchMobile = () => {
        const initialValues = {};
        const url = "/all-nfts?warnings=true";

        return <>
                        
            <div className="row">
                <SearchFormMobile 
                    initialValues={initialValues}
                    onSubmit={
                        async (values) => {
                            try {
                                //const { url } = await fetch('/s3Url').then(res => res.json())
                                //console.log(url)
                                // https://axios-http.com/docs/instance
                                // axios#post(url[, data[, config]])
                                //console.log(values);
                                
                                console.log(values.collectionId);
                                if ( values.collectionId && values.collectionId !== 'undefined' ) {
                                    window.location.assign(`/collection/${values.collectionId}`);
                                }                                

                                else if (values.searchBar){

                                    let paramsNew = url;

                                    if ( paramsNew != (url + '?' ) ) {
                                        paramsNew += '&';
                                    }
                                    paramsNew += 'searchBar=' + values.search;

                                    window.location.assign(paramsNew);
                                }    
                                
                            } catch (error) {
                                // This should be visible in FS and (hopefully) alert FS of the issue
                                console.error(error.response)
                            }
                        }
                    }
                />
            </div>
            
        </>;
    }

    return (
        <>   
        {/*MOBILE*/}
        <div className="d-block d-xl-none">
            <Navbar fixed="top" collapseOnSelect expand="xl" variant="dark" style={{backgroundColor: "#120B21" }}>
                
                    <Navbar.Brand style={{marginLeft:"10px", marginRight:"10px"}}>
                        <Link to="/">
                            <img
                            alt=""
                            src="/images/future-nft-mints-logo.png"
                            height="30"
                            className="d-inline-block align-top"
                            style={{marginRight:"0px"}}
                            />{' '}
                        </Link>
                    </Navbar.Brand>
                    
                    {renderSearchMobile()}
                    {alertsMobile(alertsCount)}
                    
                    
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" style={{marginRight:"20px"}}/>                        
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="me-auto">

                        </Nav>                            

                        <div>                                
                                <Nav className="d-inline-block" style={{ color: "#ffffff", verticalAlign: "baseline" }}activeStyle={{ backgroundColor: "transparent"}}>
                                    <WalletButton accountType={accountType} username={username}/>
                                </Nav>                                
                        </div>
                                                
                        <RenderMobileBadges accountType={accountType} />

                        <hr style={{border: "0.5px solid #ffffff", width: "100%", margin:"auto"}}/>

                        { accountType !== undefined ?
                            <Navbar.Toggle bsPrefix="nothing" as="span" label="none" >
                                <Link to="/dashboard/home" className="nav-link" id="responsive-navbar-nav" style={{ color: "#ffffff", verticalAlign: "baseline" }}>
                                    Dashboard
                                </Link>
                            </Navbar.Toggle>     
                            :
                            null
                        }                        

                        { accountType !== undefined ?
                            <Navbar.Toggle bsPrefix="nothing" as="span" label="none" >
                                <Link to="/benefits" className="nav-link" style={{ color: "#ffffff", verticalAlign: "baseline", textDecoration:"none" }}>
                                    Benefits
                                </Link>
                            </Navbar.Toggle>
                            :
                            <Navbar.Toggle bsPrefix="nothing" as="span" label="none" >
                                <Link to="/all-benefits" className="nav-link" style={{ color: "#ffffff", verticalAlign: "baseline", textDecoration:"none" }}>
                                    Benefits
                                </Link>
                            </Navbar.Toggle>
                        }
                        
                        <Navbar.Toggle bsPrefix="nothing" as="span" label="none" >
                            <Link to="/all-nfts" className="nav-link" style={{ color: "#ffffff", verticalAlign: "baseline", textDecoration:"none" }}>
                                Research
                            </Link>
                        </Navbar.Toggle>

                        <Navbar.Toggle bsPrefix="nothing" as="span" label="none" >
                            <Link to="/giveaways" className="nav-link" style={{ color: "#ffffff", verticalAlign: "baseline", textDecoration:"none" }}>
                                Giveaways
                            </Link>
                        </Navbar.Toggle> 

                        <Navbar.Toggle bsPrefix="nothing" as="span" label="none" >
                            <Link to="/leaderboard" className="nav-link" style={{ color: "#ffffff", verticalAlign: "baseline", textDecoration:"none" }}>
                                Leaderboard
                            </Link>
                        </Navbar.Toggle>                        

                        <hr style={{border: "0.5px solid #ffffff", width: "100%", margin:"auto"}}/>

                        { accountType !== undefined ?
                            <Navbar.Toggle bsPrefix="nothing" as="span" label="none" >
                                <Link to="/settings/notifications" className="nav-link" id="responsive-navbar-nav" style={{color:"#ffffff", textDecoration:"none"}} activeStyle={{ backgroundColor: "transparent"}}>                        
                                    Profile Settings
                                </Link> 
                            </Navbar.Toggle>    
                            :
                            null
                        }          

                        {accountType === undefined ? signupMobile() : accountType !== 'user' && accountType !== 'admin' && accountType !== 'researcher' && accountType !== 'owner' ? upgradeMobile() : null}      
                        
                        <Nav className="d-inline-block" style={{padding:"8px 16px"}}>
                            <a target="_blank" rel="noreferrer" style={{ color:"#fff", textDecoration:"none", marginRight:"10px"}} href="https://twitter.com/futurenftmints">
                                <img
                                    alt=""
                                    src="/images/twitter.png"
                                    height="20"
                                    className="d-inline-block align-center"
                                />
                            </a>
                            <a target="_blank" rel="noreferrer" style={{ color:"#fff", textDecoration:"none", marginRight:"10px"}} href="https://discord.gg/futurenftmints">
                                <img
                                    alt=""
                                    src="/images/discord.png"
                                    height="20"
                                    className="d-inline-block align-center"
                                />
                            </a>                            
                        </Nav>
                                                    
                        {accountType === undefined ? 
                        <div>                                
                                <Nav className="d-inline-block" style={{ color: "#ffffff", verticalAlign: "baseline" }}>
                                    <WalletButton registerLink={true} />
                                </Nav>                                
                        </div>   
                        : 
                        <Navbar.Toggle bsPrefix="nothing" as="span" label="none" >
                            <Link to="/logout" className="nav-link" id="responsive-navbar-nav" style={{color:"#ffffff", textDecoration:"none"}} activeStyle={{ backgroundColor: "transparent"}}>
                                        Logout
                            </Link>       
                        </Navbar.Toggle>
                        }                                                     

                    </Navbar.Collapse>
                
            </Navbar>
        </div>

        {/*DESKTOP*/}
        <div className="d-xl-block d-none">
            <Navbar fixed="top" collapseOnSelect expand="lg" variant="dark" style={{backgroundColor: "#120B21", height:"56px"}}>
                
                    <Navbar.Brand style={{marginLeft:"20px"}}>
                        <Link to="/">
                            <img
                            alt=""
                            src="/images/future-nft-mints-logo.png"
                            height="30"
                            className="d-inline-block align-top"
                            />{' '}
                        </Link>
                    </Navbar.Brand>

                    {renderSearchDesktop()}
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" style={{marginRight:"20px"}}/>
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="me-auto">

                        </Nav>                                                                            
                        
                        { accountType !== undefined ?
                            <Link to="/dashboard/home" className="nav-link" id="responsive-navbar-nav" style={{ color: "#ffffff", verticalAlign: "baseline" }}>
                                Dashboard
                            </Link>
                            :
                            null
                        }
                        
                        { accountType !== undefined ?
                            <Link to="/benefits" className="nav-link" style={{ color: "#ffffff", verticalAlign: "baseline", textDecoration:"none" }}>
                                Benefits
                            </Link>
                            :
                            <Link to="/all-benefits" className="nav-link" style={{ color: "#ffffff", verticalAlign: "baseline", textDecoration:"none" }}>
                                Benefits
                            </Link>
                        }                        

                        <Link to="/all-nfts" className="nav-link" style={{ color: "#ffffff", verticalAlign: "baseline", textDecoration:"none" }}>
                            Research
                        </Link>

                        <Link to="/giveaways" className="nav-link" style={{ color: "#ffffff", verticalAlign: "baseline", textDecoration:"none" }}>
                            Giveaways
                        </Link>

                        
                        <Nav className="d-inline-block" style={{padding:"8px 16px"}}>                            
                            <a target="_blank" rel="noreferrer" style={{ color:"#fff", textDecoration:"none", marginRight:"10px"}} href="https://twitter.com/futurenftmints">
                                <img
                                    alt=""
                                    src="/images/twitter.png"
                                    height="20"
                                    className="d-inline-block align-center"
                                />
                            </a>
                            <a target="_blank" rel="noreferrer" style={{ color:"#fff", textDecoration:"none"}} href="https://discord.gg/futurenftmints">
                                <img
                                    alt=""
                                    src="/images/discord.png"
                                    height="20"
                                    className="d-inline-block align-center"
                                />
                            </a>                            
                        </Nav>       

                        {alertsIcon(alertsCount)}
                        
                        <Nav style={{ color: "#ffffff", verticalAlign: "baseline"}}>
                            <WalletButton accountType={accountType} username={username}/>
                        </Nav>

                        {accountType === undefined ? 
                        <Nav style={{ color: "#ffffff", verticalAlign: "baseline"}}>
                            <WalletButton registerLink={true} />
                        </Nav>
                        :
                        null }
                                                    

                    </Navbar.Collapse>
                
            </Navbar>
        </div>
    </>


        );
}

function mapStateToProps({ auth, registeredUser, wallet, alerts }) {
    return { auth, registeredUser, wallet, alerts };
}

export default connect(mapStateToProps, actions)(Header);
