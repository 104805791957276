// constants
// import Web3EthContract from "web3-eth-contract";
// import Web3 from "web3";
import { ethers } from "ethers";
// log
// import { fetchData } from "./dataActions";

import mintPageConfig from '../config/mintPageConfig';
import mintPageAbi from '../config/mintPageAbi';

// const connectRequest = () => {
//   return {
//     type: "CONNECTION_REQUEST",
//   };
// };

// const connectSuccess = (payload) => {
//   return {
//     type: "CONNECTION_SUCCESS",
//     payload: payload,
//   };
// };

// const connectFailed = (payload) => {
//   return {
//     type: "CONNECTION_FAILED",
//     payload: payload,
//   };
// };

// const updateAccountRequest = (payload) => {
//   return {
//     type: "UPDATE_ACCOUNT",
//     payload: payload,
//   };
// };

// export const connect = () => {
//   return async (dispatch) => {
//     dispatch(connectRequest());
//     const abi = mintPageAbi;
//     const CONFIG = mintPageConfig;
//     const { ethereum } = window;
//     const metamaskIsInstalled = ethereum && ethereum.isMetaMask;
//     if (metamaskIsInstalled) {
//       Web3EthContract.setProvider(ethereum);
//       let web3 = new Web3(ethereum);
//       try {
//         const accounts = await ethereum.request({
//           method: "eth_requestAccounts",
//         });
//         const networkId = await ethereum.request({
//           method: "net_version",
//         });
//         if (networkId == CONFIG.NETWORK.ID) {
//           const SmartContractObj = new Web3EthContract(
//             abi,
//             CONFIG.CONTRACT_ADDRESS
//           );
//           dispatch(
//             connectSuccess({
//               account: accounts[0],
//               smartContract: SmartContractObj,
//               web3: web3,
//             })
//           );
//           // Add listeners start
//           ethereum.on("accountsChanged", (accounts) => {
//             dispatch(updateAccount(accounts[0]));
//           });
//           ethereum.on("chainChanged", () => {
//             window.location.reload();
//           });
//           // Add listeners end
//         } else {
//           dispatch(connectFailed(`Change network to ${CONFIG.NETWORK.NAME}.`));
//         }
//       } catch (err) {
//         dispatch(connectFailed("Something went wrong."));
//       }
//     } else {
//       dispatch(connectFailed("Install Metamask."));
//     }
//   };
// };

// export const updateAccount = (account) => {
//   return async (dispatch) => {
//     dispatch(updateAccountRequest({ account: account }));
//     dispatch(fetchData(account));
//   };
// };

const fetchSmartContractLoading = () => {
    return {
        type: "FETCH_SMART_CONTRACT_LOADING",
    };
};

const fetchSmartContractSuccess = (payload) => {
    // console.log("FETCH_SMART_CONTRACT_SUCCESS", payload);
    return {
        type: "FETCH_SMART_CONTRACT_SUCCESS",
        payload: payload,
    };
};
  
const fetchSmartContractFailed = (payload) => {
    // console.log("FETCH_SMART_CONTRACT_FAILED", payload);
    return {
        type: "FETCH_SMART_CONTRACT_FAILED",
        payload: payload,
    };
};

export const fetchSmartContract = (wallet) => {
    return async (dispatch) => {
      dispatch(fetchSmartContractLoading());

      const { provider, account, signer, network } = wallet;

      if (provider) { // https://docs.ethers.io/v5/api/providers/provider/#Provider
        try {
          if (network?.chainId == mintPageConfig.NETWORK.ID) {
            // https://docs.ethers.io/v5/api/contract/
            const tokenContract = new ethers.Contract(mintPageConfig.CONTRACT_ADDRESS, mintPageAbi, provider);
            const tokenContractRw = new ethers.Contract(mintPageConfig.CONTRACT_ADDRESS, mintPageAbi, signer);
            dispatch(
              fetchSmartContractSuccess({
                account: account,
                smartContract: tokenContract,
                smartContractRw: tokenContractRw,
              })
            );

          } else {
            dispatch(fetchSmartContractFailed(`Change network to ${mintPageConfig.NETWORK.NAME}.`));
          }
        } catch (err) {
          console.log(err);
          dispatch(fetchSmartContractFailed("Something went wrong."));
        }
      } else {
        dispatch(fetchSmartContractFailed("No ethereum provider."));
      }
    };
};
