import axios from 'axios';
import { FETCH_USER, FETCH_SURVEYS } from './types';
import { SET_WALLET_PROVIDER, SET_WALLET_ACCOUNT, SET_WALLET_SIGNER, SET_WALLET_NETWORK } from './types';
import { FETCH_REGISTERED_USER, FETCH_CURRENT_ETHEREUM_ACCOUNT } from './types';
import { SET_TOKEN_GATED_ACCESS_PRIORITIZED_ACCESS_TOKEN } from './types';
import { SET_REFRESH_WALLET_TOKENS, SET_REFRESH_CONTRACT_MEMBERSHIPS } from './types';
import { FETCH_ALERTS_COUNT } from './types';

export { fetchData } from './dataActions';
export { fetchSmartContract } from './blockchainActions';

export const fetchUser = () => async dispatch => {
    const res = await axios.get('/api/current_user');

    dispatch({ type: FETCH_USER, payload: res.data });
};

export const fetchRegisteredUser = (account) => async dispatch => {
    const res = await axios.get(`/api/current_registered_user?account=${account}`);

    dispatch({ type: FETCH_REGISTERED_USER, payload: res.data });
};

export const handleToken = token => async dispatch => {
    const res = await axios.post('/api/stripe', token);

    dispatch({ type: FETCH_USER, payload: res.data });
};

export const submitSurvey = (values, history) => async dispatch => {
    const res = await axios.post('/api/surveys', values);

    history.push('/surveys');
    dispatch({ type: FETCH_USER, payload: res.data });
};

export const fetchSurveys = () => async dispatch => {
    const res = await axios.get('/api/surveys');

    dispatch({ type: FETCH_SURVEYS, payload: res.data});
};

export const submitRegister = (values) => async dispatch => {
    //console.log("test");
    //console.log(values);

    const res = await axios.post('/api/register', values);

    //values.history.push('/');
    dispatch({ type: FETCH_USER, payload: res.data });
};

export const setWalletProvider = (payload) => async dispatch => {
    dispatch({ type: SET_WALLET_PROVIDER, payload });
};

export const setWalletAccount = (payload) => async dispatch => {
    dispatch({ type: SET_WALLET_ACCOUNT, payload });
};

export const setWalletSigner = (payload) => async dispatch => {
    dispatch({ type: SET_WALLET_SIGNER, payload });
};

export const setWalletNetwork = (payload) => async dispatch => {
    dispatch({ type: SET_WALLET_NETWORK, payload });
};

export const fetchCurrentEthereumAccount = () => async dispatch => {
    const res = await axios.get('/auth/ethereum/current_account');

    dispatch({ type: FETCH_CURRENT_ETHEREUM_ACCOUNT, payload: res.data });
};

export const refreshTokenContractAddress = () => async dispatch => {
    const res = await axios.put('/api/users/refresh_token_contract_address');
    const { tokenContractAddress } = res.data;

    dispatch({ type: SET_TOKEN_GATED_ACCESS_PRIORITIZED_ACCESS_TOKEN, payload: { prioritizedAccessToken: tokenContractAddress } });
};

export const refreshWalletTokens = () => async dispatch => {
    await axios.put('/api/users/refresh_users_wallet_tokens');

    dispatch({ type: SET_REFRESH_WALLET_TOKENS, payload: { walletTokens: false } });
};
export const refreshContractMemberships = () => async dispatch => {
    await axios.put('/api/users/refresh_users_contract_memberships');

    dispatch({ type: SET_REFRESH_CONTRACT_MEMBERSHIPS, payload: { contractMemberships: false } });
};

export const fetchAlertsCount = () => async dispatch => {
    const res = await axios.get('/api/get/alerts-count');

    dispatch({ type: FETCH_ALERTS_COUNT, payload: res.data });
};
