//
// These are token definitions for the front-end
// Any token here should also be added to accessTokens in config/dev.js and config/prod.js
//
// Note the order will inform useTokenGatedAccess of priority
//

/*



TRUSTY DECIMAL TO HEX CALCULATOR

https://www.inchcalculator.com/decimal-to-hex-converter/
https://www.browserling.com/tools/dec-to-hex


OLD
 
*/

module.exports = [
    {
        name: "Future NFT Mints - Genesis NFT",
        // symbol: "FNFTM0", // this is descriptive data, but the app currently does not depend on it
        contractAddress: "0x5f66f72a4f87ec3cd06241400bd2ba867f1233c7",
        expiresOn: 'Lifetime',
        message: "Welcome to Future NFT Mints! We're excited to have you!",
        imageUrl: "https://lh3.googleusercontent.com/RT-4ZAzQ4GUIQZgPiIaTibWAkRuO-6hJb8qtDEjZaijVvXP1V5PtAy44Q0xqk3UnvyXncB6BIRAUIO129Ntfdg_HIPECCQyhhIv73g=s168"
    },
    {
        name: "Crypto Packaged Goods",
        // symbol: "CPG",
        contractAddress: "0x837b40be9ce60c79b63d1356a5f9fcad721421ec",
        expiresOn: 'Nov 30, 2022',
        message: "Welcome Crypto Package Goods! Please enjoy your Premium access.",
        imageUrl: "https://lh3.googleusercontent.com/YeBTuhBMz-YMrigj4_lofGAlxSElo06yivax8bTt0-cWs5fTw7Ta8yAaaSrCl_ji-CQWm6CveSvDMkdjDqKkxGrYOXb27bPJNIMSe4A=s168"
    },
    {
        name: "Meta Angels",
        // symbol: "",
        contractAddress: "0xad265ab9b99296364f13ce5b8b3e8d0998778bfb",
        expiresOn: 'Nov 30, 2022',
        message: "Welcome Meta Angels! Please enjoy your Premium access.",
        imageUrl: "https://lh3.googleusercontent.com/J4sUEAJDbvc-6bgkHQyNtQRxlFr1CkZZ1d14DSGR1x7miJZN0AXcV81Hlc9yQEx3XkyAnvbeFukFQlODyNDjwBcIvHOsh7eeYTIQnWs=s168"
    },   
    {
        name: "CPG Pop",
        // symbol: "CPG",
        contractAddress: "0x7eaa96d48380802a75ed6d74b91e2b30c3d474c1",
        expiresOn: 'Nov 30, 2022',
        message: "Welcome CPG Pop! Please enjoy your Premium access.",
        imageUrl: "https://lh3.googleusercontent.com/-f5LCFHoafvT2X6pKG69QWkB5tNMraXCv-vFsAIZle6iRqeNM-oxSv1o7maux-Q4GsJtgu_1mfM1362U9Jkh3FxhYMk0dxQlXQs9eA=s168"
    },    
    {
        // References for this data:
        // https://opensea.io/assets/ethereum/0x495f947276749ce646f68ac8c248420045cb7b5e/96909412274740347131504706166699741407580229373431542530097899435283405865460
        // https://etherscan.io/nft/0x495f947276749ce646f68ac8c248420045cb7b5e/96909412274740347131504706166699741407580229373431542530097899435283405865460
        // https://etherscan.io/token/0x495f947276749ce646f68ac8c248420045cb7b5e?a=96909412274740347131504706166699741407580229373431542530097899435283405865460
        // https://etherscan.io/token/0x495f947276749ce646f68ac8c248420045cb7b5e
        name: "Floor App Early Supporter Token",
        // symbol: "OPENSTORE",
        contractAddress: "0x495f947276749ce646f68ac8c248420045cb7b5e", // OpenSea Contract
        // tokenId: "96909412274740347131504706166699741407580229373431542530097899435283405865460",
        hexTokenId: "0xd640c898b0902bd02f69de0fe8d0bd560956db760000000000000300000001f4",
        expiresOn: 'Nov 30, 2022',
        message: "Welcome Floor! Please enjoy your Premium access.",
        imageUrl: "https://lh3.googleusercontent.com/UdWcZMYItLakIAufZ7pB_tmRBYnR0HczztfQNL2fzR-nA3vHR6vWjPIo0RsFLAl-O3eS-vCbD-L4dIv7wn57nkCt30Zi9IZkRED81w=s168"
    },
    // You an add another token similar to Floor App Gen 1 like this:
    // {
    //     name: "Some Token Sold with OpenSea Contract",
    //     // symbol: "OPENSTORE",
    //     contractAddress: "0x495f947276749ce646f68ac8c248420045cb7b5e", // OpenSea Contract
    //     // tokenId: "integerTokenId",
    //     hexTokenId: "hextTokenID of another token",
    //     expiresOn: 'TBD'
    // },
    {
        name: "Floor Gen 2",
        // nickname: "Floor Beta", // proposed as potential nickname for FNFTM app to use, since FloorNFTs is arguably not a great description
        // symbol: "FLRNFTNY",
        contractAddress: "0xa0c3122e71fd972e868fddae76e3ed2b174ceaf9",
        expiresOn: 'Nov 30, 2022',
        message: "Welcome Floor! Please enjoy your Premium access.",
        imageUrl: "https://lh3.googleusercontent.com/UdWcZMYItLakIAufZ7pB_tmRBYnR0HczztfQNL2fzR-nA3vHR6vWjPIo0RsFLAl-O3eS-vCbD-L4dIv7wn57nkCt30Zi9IZkRED81w=s168"
    },
    {
        name: "Floor Gen 2",
        // nickname: "Floor Beta", // proposed as potential nickname for FNFTM app to use, since FloorNFTs is arguably not a great description
        // symbol: "FLRNFTNY",
        contractAddress: "0x495f947276749ce646f68ac8c248420045cb7b5e",
        // tokenId: "96909412274740347131504706166699741407580229373431542530097899438581940748318",            
        hexTokenId: "0xd640c898b0902bd02f69de0fe8d0bd560956db7600000000000006000000001e",      
        expiresOn: 'Nov 30, 2022',
        message: "Welcome Floor! Please enjoy your Premium access.",
        imageUrl: "https://lh3.googleusercontent.com/UdWcZMYItLakIAufZ7pB_tmRBYnR0HczztfQNL2fzR-nA3vHR6vWjPIo0RsFLAl-O3eS-vCbD-L4dIv7wn57nkCt30Zi9IZkRED81w=s168"
    },
    {
        name: "Floor Gen 2",
        // nickname: "Floor Beta", // proposed as potential nickname for FNFTM app to use, since FloorNFTs is arguably not a great description
        // symbol: "FLRNFTNY",
        contractAddress: "0x495f947276749ce646f68ac8c248420045cb7b5e",
        // tokenId: "96909412274740347131504706166699741407580229373431542530097899437482429120592",            
        hexTokenId: "0xd640c898b0902bd02f69de0fe8d0bd560956db76000000000000050000000050",  
        expiresOn: 'Nov 30, 2022',
        message: "Welcome Floor! Please enjoy your Premium access.",
        imageUrl: "https://lh3.googleusercontent.com/UdWcZMYItLakIAufZ7pB_tmRBYnR0HczztfQNL2fzR-nA3vHR6vWjPIo0RsFLAl-O3eS-vCbD-L4dIv7wn57nkCt30Zi9IZkRED81w=s168"
    },
    {
        name: "Floor Gen 2",
        // nickname: "Floor Beta", // proposed as potential nickname for FNFTM app to use, since FloorNFTs is arguably not a great description
        // symbol: "FLRNFTNY",
        contractAddress: "0x495f947276749ce646f68ac8c248420045cb7b5e",
        // tokenId: "96909412274740347131504706166699741407580229373431542530097899436382917492936",            
        hexTokenId: "0xd640c898b0902bd02f69de0fe8d0bd560956db760000000000000400000000c8",
        expiresOn: 'Nov 30, 2022',
        message: "Welcome Floor! Please enjoy your Premium access.",
        imageUrl: "https://lh3.googleusercontent.com/UdWcZMYItLakIAufZ7pB_tmRBYnR0HczztfQNL2fzR-nA3vHR6vWjPIo0RsFLAl-O3eS-vCbD-L4dIv7wn57nkCt30Zi9IZkRED81w=s168"
    },
    {
        name: "Floor Gen 3",
        // symbol: "FLOORIII",
        contractAddress: "0x2d92c4f9f75308d0b9b098b142369032e4f901ff",
        expiresOn: 'Nov 30, 2022',
        message: "Welcome Floor! Please enjoy your Premium access.",
        imageUrl: "https://lh3.googleusercontent.com/UdWcZMYItLakIAufZ7pB_tmRBYnR0HczztfQNL2fzR-nA3vHR6vWjPIo0RsFLAl-O3eS-vCbD-L4dIv7wn57nkCt30Zi9IZkRED81w=s168"
    },
    {
        name: "Champions Ascension",
        // symbol: "",
        contractAddress: "0x13d15d8b7b2bf48cbaf144c5c50e67b6b635b5cd",
        expiresOn: 'Nov 30, 2022',
        message: "Welcome Champions Ascension! Please enjoy your Premium access.",
        imageUrl: "https://openseauserdata.com/files/9ffae612e0c8ea54537f646b9c144c6f.png"
    },
    {
        name: "KronicKatz",
        // symbol: "",
        contractAddress: "0x19534c6bC37fD44C93F3a6506E44F32a99670f43",
        expiresOn: 'Nov 30, 2022',
        message: "Welcome KronicKatz! Please enjoy your Premium access.",
        imageUrl: "https://lh3.googleusercontent.com/PFz08-lblPxtzmY02TFr8UVVF4HMFv9vvZk2zaC0HOPm9mDbS5noBlLSHbUlx_1Nu0LsvU9swODso5QpPG9O0IStmBAabpS9KJu8jA=s0"
    },    
    {
        name: "Kingdom NFT",
        // nickname: "Floor Beta", // proposed as potential nickname for FNFTM app to use, since FloorNFTs is arguably not a great description
        // symbol: "FLRNFTNY",
        contractAddress: "0x495f947276749ce646f68ac8c248420045cb7b5e",
        // tokenId: "66140375009567476496417212283140734968727661177751206863825933020156884156916",            
        hexTokenId: "0x923a2029f14e6e48de5d8ebe38b64ec95388892c0000000000000400000001f4",  
        expiresOn: 'Nov 30, 2022',
        message: "Welcome Kingdom! Please enjoy your Premium access.",
        imageUrl: "https://lh3.googleusercontent.com/dkyz8gZhTh4fel0pGD-Q1OyADsqw4g7wCZrfpGmcu-SsCCTDV0LSWLuzQItoA8wo7Rij9OOT6KaKcQpaUPWWWb19d-xwWLZY1enPZw=s0"
    }, 
    {
        name: "Kingdom NFT",
        // nickname: "Floor Beta", // proposed as potential nickname for FNFTM app to use, since FloorNFTs is arguably not a great description
        // symbol: "FLRNFTNY",
        contractAddress: "0x495f947276749ce646f68ac8c248420045cb7b5e",
        // tokenId: "66140375009567476496417212283140734968727661177751206863825933019057372529140",            
        hexTokenId: "0x923a2029f14e6e48de5d8ebe38b64ec95388892c0000000000000300000001f4",  
        expiresOn: 'Nov 30, 2022',
        message: "Welcome Kingdom! Please enjoy your Premium access.",
        imageUrl: "https://lh3.googleusercontent.com/dkyz8gZhTh4fel0pGD-Q1OyADsqw4g7wCZrfpGmcu-SsCCTDV0LSWLuzQItoA8wo7Rij9OOT6KaKcQpaUPWWWb19d-xwWLZY1enPZw=s0"
    }, 
    {
        name: "The Nifty Portal",
        // symbol: "",
        contractAddress: "0xd374410e9bb22f3771ffbd0b40a07c0cf44a04fc",
        expiresOn: 'Nov 30, 2022',
        message: "Welcome Nifty Portal! Please enjoy your Premium access.",
        imageUrl: "https://lh3.googleusercontent.com/V61m1w9IYCQHxLXfS0VMydS7njqAd62RlDUH91YU5Q836ywyrkjCQgqTjEJBJCvRcYcq1Uh2x2ydnw5T14bNJHklNS_G4jk2Buz5=s0"        
    },
    {
        name: "Example Token",
        // symbol: "EXAMPLE",
        contractAddress: "0x0000",
        expiresOn: 'June 1',
        message: ""
    },
]
