// log
import store from "../redux/store";

const fetchDataRequest = () => {
  return {
    type: "CHECK_DATA_REQUEST",
  };
};

const fetchDataSuccess = (payload) => {
  return {
    type: "CHECK_DATA_SUCCESS",
    payload: payload,
  };
};

const fetchDataFailed = (payload) => {
  return {
    type: "CHECK_DATA_FAILED",
    payload: payload,
  };
};

export const fetchData = (account) => {
  return async (dispatch) => {
    dispatch(fetchDataRequest());
    try {
      let blockchain = store.getState().blockchain; // blockhain is a top-level key in the redux store
      let smartContract = blockchain.smartContract;
      let totalSupply = (await smartContract.totalSupply()).toString();
      let isAllowListMintEnabled = (await smartContract.isAllowListMintEnabled());
      let isPresaleMintEnabled = (await smartContract.isPresaleMintEnabled());
      let isPublicMintEnabled = (await smartContract.isPublicMintEnabled());
      let balance = (await smartContract.balanceOf(account)).toString();
      let allowListAllocation = (await smartContract.allowList(account));
      let presaleListAllocation = (await smartContract.presaleList(account));

    //   console.log({
    //     totalSupply,
    //     isAllowListMintEnabled,
    //     isPresaleMintEnabled,
    //     isPublicMintEnabled,
    //     balance,
    //     allowListAllocation,
    //     presaleListAllocation
    //   })

      dispatch(
        fetchDataSuccess({
          totalSupply,
          isAllowListMintEnabled,
          isPresaleMintEnabled,
          isPublicMintEnabled,
          balance,
          allowListAllocation,
          presaleListAllocation
        })
      );
    } catch (err) {
      console.log(err);
      dispatch(fetchDataFailed("Could not load data from contract."));
    }
  };
};
