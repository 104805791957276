import { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { fetchData, fetchSmartContract } from "../../actions";
import * as s from "../../styles/mintPage";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import PleaseConnectWallet from '../access/PleaseConnectWallet';

import mintPageConfig from '../../config/mintPageConfig';

import axios from 'axios';
    
const Mint = ({ wallet }) => {
    const referrer = window.location.pathname.replace("/", "") || "none";
    const { account } = wallet; // use the account from the wallet store object

    const dispatch = useDispatch();
    const blockchain = useSelector((state) => state.blockchain);
    const data = useSelector((state) => state.data);
    const [claimingNft, setClaimingNft] = useState(false);
    const [feedback, setFeedback] = useState(`Click buy to mint your NFT.`);
    const [mintRemaining, setMintRemaining] = useState();
    const [mintAmount, setMintAmount] = useState(1);
    const [CONFIG, SET_CONFIG] = useState({
        CONTRACT_ADDRESS: "",
        SCAN_LINK: "",
        NETWORK: {
          NAME: "",
          SYMBOL: "",
          ID: 0,
        },
        NFT_NAME: "",
        SYMBOL: "",
        MAX_SUPPLY: 1,
        WEI_COST: 0,
        DISPLAY_COST: 0,
        GAS_LIMIT: 0,
        MARKETPLACE: "",
        MARKETPLACE_LINK: "",
        SHOW_BACKGROUND: false,
    });

    const claimNFTs = () => {
        let cost = CONFIG.WEI_COST;
        let gasLimit = CONFIG.GAS_LIMIT;
        let totalCostWei = String(cost * mintAmount);
        let totalGasLimit = String(gasLimit * mintAmount);
        /*
        console.log("Cost: ", totalCostWei);
        console.log("Gas limit: ", totalGasLimit);
        console.log("totalSupply: ", data.totalSupply)
        console.log("isAllowListMintEnabled: ", data.isAllowListMintEnabled)
        console.log("isPresaleMintEnabled: ", data.isPresaleMintEnabled)
        console.log("isPublicMintEnabled: ", data.isPublicMintEnabled)
        */
    
        if (data.isAllowListMintEnabled) {
          setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
          setMintRemaining();
          setClaimingNft(true);
    
          if (data.isPublicMintEnabled) {
            publicMint(totalCostWei, totalGasLimit)
          } else if (data.isPresaleMintEnabled) {
            presaleMint(totalCostWei, totalGasLimit)
          } else {
            allowListMint(totalCostWei, totalGasLimit)
          }
        } else {
          console.log('mint phases not enabled')
        }
    };
    
    const allowListMint = async (totalCostWei, totalGasLimit) => {
        try {
            const mint = await blockchain.smartContractRw.allowListMint(mintAmount, {
                gasLimit: String(totalGasLimit),
                from: blockchain.account,
                value: totalCostWei,
            });
            await mint.wait();

            setFeedback(
                `WOW, the ${CONFIG.NFT_NAME} is yours! Go visit OpenSea to view it.`
            );
            setMintRemaining();
            axios.get(`https://app.futurenftmints.com/api/referral/${CONFIG.CONTRACT_ADDRESS}/${blockchain.account}/${referrer}/${mintAmount}`); 
            //console.log('sent referral');
            setClaimingNft(false);
            dispatch(fetchData(blockchain.account));
        } catch (err) {
            console.log(err);
            setFeedback("Sorry, something went wrong please try again later.");
            setMintRemaining();
            setClaimingNft(false);
        }
    };
    
    const presaleMint = async (totalCostWei, totalGasLimit) => {
        try {
            const mint = await blockchain.smartContractRw.presaleMint(mintAmount, {
                gasLimit: String(totalGasLimit),
                from: blockchain.account,
                value: totalCostWei,
            });
            await mint.wait();

            setFeedback(
                `WOW, the ${CONFIG.NFT_NAME} is yours! Go visit OpenSea to view it.`
            );
            setMintRemaining();
            axios.get(`https://app.futurenftmints.com/api/referral/${CONFIG.CONTRACT_ADDRESS}/${blockchain.account}/${referrer}/${mintAmount}`); 
            //console.log('sent referral');
            setClaimingNft(false);
            dispatch(fetchData(blockchain.account));
        } catch (err) {
            console.log(err);
            setFeedback("Sorry, something went wrong please try again later.");
            setMintRemaining();
            setClaimingNft(false);
        }
    };
    
    const publicMint = async (totalCostWei, totalGasLimit) => {
        try {
            const mint = await blockchain.smartContractRw.publicMint(mintAmount, {
                gasLimit: String(totalGasLimit),
                from: blockchain.account,
                value: totalCostWei,
            });
            await mint.wait();

            setFeedback(
                `WOW, the ${CONFIG.NFT_NAME} is yours! Go visit OpenSea to view it.`
            );
            setMintRemaining();
            axios.get(`https://app.futurenftmints.com/api/referral/${CONFIG.CONTRACT_ADDRESS}/${blockchain.account}/${referrer}/${mintAmount}`); 
            //console.log('sent referral');
            setClaimingNft(false);
            dispatch(fetchData(blockchain.account));
        } catch (err) {
            console.log(err);
            setFeedback("Sorry, something went wrong please try again later.");
            setMintRemaining();
            setClaimingNft(false);
        }
    };

    const decrementMintAmount = () => {
        let newMintAmount = mintAmount - 1;
        if (newMintAmount < 1) {
          newMintAmount = 0;
        }
        setMintAmount(newMintAmount);
    };
    
    const incrementMintAmount = () => {
        let newMintAmount = mintAmount + 1;
        if (newMintAmount > maxRemainingPerAddressDuringMint()) {
          newMintAmount = maxRemainingPerAddressDuringMint();
        }
        setMintAmount(newMintAmount);
    };

    const maxRemainingPerAddressDuringMint = () => {
        const presaleLimit = 2,
              publicLimit = 5;
    
        let limit = 0;
        if (data.isPublicMintEnabled) {
          limit = publicLimit
        } else if (data.isPresaleMintEnabled || data.isAllowListMintEnabled) {
          limit = presaleLimit
        }
    
        return data.balance >= limit ? 0 : (limit - data.balance);
    }

    const getData = () => {
        if (wallet.account && blockchain.smartContract !== null) {
            dispatch(fetchData(wallet.account));
        }
    };

    const getConfig = async () => {
        SET_CONFIG(mintPageConfig);
    };

    const getFeedback = async () => {
        let currentPhase,
            maxRemainingMint
    
        let balance = parseInt(data.balance),
            allowListAllocation = parseInt(data.allowListAllocation),
            presaleListAllocation = parseInt(data.presaleListAllocation)
    
        if (data.isPublicMintEnabled) {
          currentPhase = 'Public'
          maxRemainingMint = 5 - balance
        } else if (data.isPresaleMintEnabled) {
          currentPhase = 'Presale'
          maxRemainingMint = presaleListAllocation - balance
        } else if (allowListAllocation) {
          currentPhase = 'Allow List'
          maxRemainingMint = 2 - balance
        } else {
          maxRemainingMint = 0
          currentPhase = 'this'
        }
    
        setFeedback(`${account}`)
        setMintRemaining(`can mint up to ${maxRemainingMint}`)
    };

    useEffect(() => {
        getConfig();
    }, []);

    useEffect(() => {
        dispatch(fetchSmartContract(wallet));
    }, [wallet]);
    
    useEffect(() => {
        getFeedback();
    }, [wallet.account, data.allowListAllocation, data.presaleListAllocation]);

    useEffect(() => {
        if (blockchain.smartContract) getData()
    }, [wallet.account, blockchain.smartContract]);
    
    const progressBar = () => {
        if (wallet.account && blockchain.smartContract !== null) {
          return <><Row>
                <Col>
                  <div className="progress">
                    <div className="progress-bar" role="progressbar" style={{width: `${data.totalSupply / CONFIG.MAX_SUPPLY * 100}%`, backgroundColor:"#F83700"}} aria-valuemin="0" aria-valuemax="100" ></div>
                  </div>
                </Col>
              </Row>
              <Row style={{ paddingTop:"5px" }}>
                <Col style={{ textAlign: "left", color: "#ffffff" }}>0.25 ETH</Col>
                <Col style={{ textAlign: "right", color: "#ffffff" }}>{data.totalSupply} / {CONFIG.MAX_SUPPLY}</Col>
              </Row>
              </>
        }
    }
    
    const shouldRenderConnectedWalletMintUI = () => {
        return data.isAllowListMintEnabled && parseInt(data.allowListAllocation) && (parseInt(data.allowListAllocation) - parseInt(data.balance) ) > 0 ||
          data.isPresaleMintEnabled && parseInt(data.presaleListAllocation) && (parseInt(data.presaleListAllocation) - parseInt(data.balance) ) ||
          data.isPublicMintEnabled
    }

    const shouldRenderFeedbackMessage = () => {
        return (wallet.account && blockchain.smartContract !== null)
    }
    
    const feedbackMessage = () => {
        return shouldRenderFeedbackMessage() ? <Row
          style={{
            color: "#ffffff",
          }}
        >
          <Col style={{textAlign: "center", fontSize:"0.8em", paddingTop:"10px" }}>{feedback}<br />{mintRemaining}</Col>
        </Row> : null
    }
    
    const connectedWalletMintUI = () => {
    
        return shouldRenderConnectedWalletMintUI() ? <>
          <s.SpacerSmall />
          <s.Container ai={"center"} jc={"center"} fd={"row"}>
            <s.StyledRoundButton
              style={{ lineHeight: 0.4 }}
              disabled={claimingNft ? 1 : 0}
              onClick={(e) => {
                e.preventDefault();
                decrementMintAmount();
              } }
            >
              -
            </s.StyledRoundButton>
            <s.SpacerMedium />
            <s.TextDescription
              style={{
                textAlign: "center",
                color: "#ffffff",
                marginTop:"auto",
                marginBottom:"auto"
              }}
            >
              {mintAmount}
            </s.TextDescription>
            <s.SpacerMedium />
            <s.StyledRoundButton
              disabled={claimingNft ? 1 : 0}
              onClick={(e) => {
                e.preventDefault();
                incrementMintAmount();
              } }
            >
              +
            </s.StyledRoundButton>
          </s.Container>
          <s.SpacerSmall />
          <s.Container ai={"center"} jc={"center"} fd={"row"}>
          <Button style={{ backgroundColor: "#F83700", border: "#F83700", width:"100%" }}
              disabled={claimingNft ? 1 : 0}
              onClick={(e) => {
                e.preventDefault();
                claimNFTs();
                getData();
              } }
            >
              {claimingNft ? "BUSY" : "BUY"}
            </Button>
          </s.Container>
          {feedbackMessage()}
        </> : null
    }

    return (
        <>   
            <Row style={{ paddingTop:"150px", paddingBottom:"500px", height:"100%", textAlign: "center" }}>
              <div className="col-md-2"></div>
              <div className="col-md-3">
                <img fluid="true" alt="Future NFT Mints - Genesis NFT Card" src="images/fnftm-card.png" width="200px" className="d-inline-block align-top"/>
              </div>
    
              <div className="col-md-5">
                <Row style={{ paddingTop:"25px", paddingBottom:"25px" }}>
                  <Col style={{ textAlign: "center", color: "#ffffff", fontSize:"1.5em" }}>Mint Genesis NFT</Col>
                </Row>
    
                {progressBar()}
    
                <Row>
    
                  <Col xs={12} style={{ paddingTop:"0px", textAlign: "center" }}>
                    {Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? (
                      <>
                        <s.TextTitle
                          style={{ textAlign: "center", color: "#ffffff" }}
                        >
                          The sale has ended.
                        </s.TextTitle>
                        <s.TextDescription
                          style={{ textAlign: "center", color: "#ffffff" }}
                        >
                          You can still find {CONFIG.NFT_NAME} on
                        </s.TextDescription>
                        <s.SpacerSmall />
                        <s.StyledLink style={{ color:"#fff", textDecoration:"underline"}} target={"_blank"} href={CONFIG.MARKETPLACE_LINK}>
                          {CONFIG.MARKETPLACE}
                        </s.StyledLink>
                      </>
                    ) : (
                      <>
                        { (!wallet.account || blockchain.smartContract === null) ? <PleaseConnectWallet /> : connectedWalletMintUI() }
                      </>
                    )}
                  </Col>
                </Row>
    
                <Row style={{ paddingTop:"10px" }}>
                  <Col>
                  <s.StyledLink style={{ color:"#fff", textDecoration:"underline", fontSize:"0.6em"}} target={"_blank"} href="https://etherscan.io/address/0x5f66F72a4f87ec3Cd06241400bD2bA867F1233c7">Smart Contract on Etherscan</s.StyledLink>
                  </Col>
                </Row>
    
                <Row  style={{ paddingBottom:"25px" }}></Row>
    
              </div>
              <div className="col-md-2"></div>
    
            </Row>
        </>
    );
}

function mapStateToProps({ wallet, blockchain }) {
    return { wallet, blockchain };
}

export default connect(mapStateToProps)(Mint);
