import { useState, useMemo, useEffect } from 'react';
import { useParams, Link, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import axios from 'axios';
import WalletButton from '../WalletButton';

const Table = ({ account }) => {        
    const publicClassName = account === undefined ? "text-center border-bottom-0" : "text-center";
    const freeClassName = account === 'free' ? "text-center border-bottom-0" : "text-center";
    const guestClassName = account === 'guest' || account === 'top 100' ? "text-center border-bottom-0" : "text-center";
    const userClassName = account === 'user' || account === 'owner' || account === 'researcher' || account === 'admin' ? "text-center border-bottom-0" : "text-center";

    const publicStyle = account === undefined ? {backgroundColor:"#7945E1", color:"#ffffff"} : {};
    const freeStyle = account === 'free' ? {backgroundColor:"#7945E1", color:"#ffffff"} : {};
    const guestStyle = account === 'guest' || account === 'top 100' ? {backgroundColor:"#7945E1", color:"#ffffff"} : {};
    const userStyle = account === 'user' || account === 'owner' || account === 'researcher' || account === 'admin' ? {backgroundColor:"#7945E1", color:"#ffffff"} : {};
    
    return (
        <>
            <div className="row table-responsive" style={{ marginTop:"20px"}}>
                    <div className="col-12">                    
                    <table className="table" style={{ borderRadius: "1em", borderCollapse: "collapse", overflow:"hidden", width:"100%" }}>
                        <thead className="thead-light text-nowrap" style={{borderBottom:"none" }}>
                            <tr style={{ backgroundColor:"#29194A", borderBottom:"2px #000000 solid", color:"rgba(255,255,255,.8)"}}>
                                <th scope="col"></th>
                                {account === undefined ? <th scope="col" className={publicClassName} style={publicStyle}>Public</th> : null}
                                <th scope="col" className={freeClassName} style={freeStyle}>Free</th>
                                {account === 'guest' ? <th scope="col" className={guestClassName} style={guestStyle}>Premium - Guest</th> : null}
                                {account === 'top 100' ? <th scope="col" className={guestClassName} style={guestStyle}>Premium - Top 100</th> : null}
                                <th scope="col" className={userClassName} style={userStyle}>Premium</th>
                            </tr>
                        </thead>
                        <tbody style={{backgroundColor:"#280B22", border:"1px #000000 solid", color:"rgba(255,255,255,.8)", fontSize:"0.8em"}}>
                            <tr>
                                <td><strong>Benefit Notifications</strong></td>
                                {account === undefined ? <td className={publicClassName} style={publicStyle}>-</td> : null}
                                <td className={freeClassName} style={freeStyle}>1 per Linked Wallet</td>
                                {account === 'guest' || account === 'top 100' ? <td className={guestClassName} style={guestStyle}>Unlimited</td> : null}
                                <td className={userClassName} style={userStyle}>Unlimited</td>
                            </tr>
                            <tr>
                                <td><strong>Linked Wallets</strong></td>
                                {account === undefined ? <td className={publicClassName} style={publicStyle}>-</td> : null}
                                <td className={freeClassName} style={freeStyle}>10</td>
                                {account === 'guest' || account === 'top 100' ? <td className={guestClassName} style={guestStyle}>Unlimited</td> : null}
                                <td className={userClassName} style={userStyle}>Unlimited</td>
                            </tr>
                            <tr>
                                <td><strong>Allowlist Tracking</strong></td>
                                {account === undefined ? <td className={publicClassName} style={publicStyle}>-</td> : null}
                                <td className={freeClassName} style={freeStyle}>&#10003;</td>
                                {account === 'guest' || account === 'top 100' ? <td className={guestClassName} style={guestStyle}>&#10003;</td> : null}
                                <td className={userClassName} style={userStyle}>&#10003;</td>
                            </tr>
                            <tr>
                                <td><strong>Watchlist</strong></td>
                                {account === undefined ? <td className={publicClassName} style={publicStyle}>-</td> : null}
                                <td className={freeClassName} style={freeStyle}>&#10003;</td>
                                {account === 'guest' || account === 'top 100' ? <td className={guestClassName} style={guestStyle}>&#10003;</td> : null}
                                <td className={userClassName} style={userStyle}>&#10003;</td>
                            </tr>                            
                            <tr>
                                <td><strong>Upcoming Mints Research</strong></td>
                                {account === undefined ? <td className={publicClassName} style={publicStyle}>3 Days</td> : null}
                                <td className={freeClassName} style={freeStyle}>5 Days</td>
                                {account === 'guest' || account === 'top 100' ? <td className={guestClassName} style={guestStyle}>Unlimited</td> : null}
                                <td className={userClassName} style={userStyle}>Unlimited</td>
                            </tr>
                            <tr>
                                <td><strong>Minted NFTs Research</strong></td>
                                {account === undefined ? <td className={publicClassName} style={publicStyle}>&#10003;</td> : null}
                                <td className={freeClassName} style={freeStyle}>&#10003;</td>
                                {account === 'guest' || account === 'top 100' ? <td className={guestClassName} style={guestStyle}>&#10003;</td> : null}
                                <td className={userClassName} style={userStyle}>&#10003;</td>
                            </tr>                            
                        </tbody>
                        <tfoot style={{ backgroundColor:"#140510", textAlign: "center", color:"rgba(255,255,255,.8)", border:"1px #000000 solid"}}>
                            <tr>                     
                                <td></td>
                                {account === undefined ? <td className={publicClassName} style={publicStyle}></td> : null}
                                <td className={freeClassName} style={freeStyle}>{account === undefined ? <WalletButton size="sm" btnColor="#ffffff" buttonCopy="Register" margin="Left" /> : null}</td>
                                {account === 'guest' || account === 'top 100' ? <td className={guestClassName} style={guestStyle}></td> : null}
                                {account === 'user' || account === 'owner' || account === 'researcher' || account === 'admin' ? <td></td> : <td className={userClassName} style={userStyle}>
                                    <Link to="/mint">
                                        <div className="d-lg-block d-none" style={{padding: "0px 0px"}}>
                                            <button className="btn btn-sm" style={{backgroundColor: "#F83700", border: "#F83700", color:"#ffffff", fontSize: "0.6em"}}>
                                                Lifetime NFT 0.25 ETH
                                            </button>                                            
                                        </div>
                                        <div className="d-block d-lg-none" style={{padding: "8px 0px"}}>
                                            <button className="btn btn-sm" style={{backgroundColor: "#F83700", border: "#F83700", color:"#ffffff", fontSize: "0.6em"}}>
                                                Genesis NFT 0.25 ETH
                                            </button>                                            
                                        </div>                                        
                                    </Link>
                                    <a href="https://heymint.xyz/future-mints-annual" target="_BLANK" rel="noreferrer" style={{color:"#ffffff"}}>
                                        <div className="d-lg-block d-none" style={{padding: "0px 0px"}}>
                                            <button className="btn btn-sm" style={{backgroundColor: "#ffffff", border: "#ffffff", color:"#F83700", fontSize: "0.6em"}}>
                                                Annual Pass 0.05 ETH
                                            </button>
                                        </div>
                                        <div className="d-block d-lg-none" style={{padding: "8px 0px"}}>
                                            <button className="btn btn-sm" style={{backgroundColor: "#ffffff", border: "#ffffff", color:"#F83700", fontSize: "0.6em"}}>
                                                Annual Pass 0.05 ETH
                                            </button>                                            
                                        </div>    
                                    </a>
                                </td>}
                            </tr>
                        </tfoot>
                    </table>
                    </div>
                </div>
        </>
    )
};

export default Table;