import { createStore, applyMiddleware } from 'redux';
import reduxThunk from 'redux-thunk';

import reducers from '../reducers';

const store = createStore(
    reducers,
    {
        
        tokenGatedAccess: {},
        wallet: {},
        registeredUser: {},
        refresh: {
            walletTokens: true,
            contractMemberships: true,
        },
        alerts: {},
        blockchain: {},
        data: {},
    },
    applyMiddleware(reduxThunk)
);

export default store;
