module.exports = {
    /*
    TESTING - RINKEBY

    "CONTRACT_ADDRESS": "0xc01a357137f188b3c3da0601b6a455a39961af9d",
    // TO DO: Update to Main net config values: https://github.com/elliotkoss/fnftm-genesismintpage/blob/main/dapp/public/config/config.json
    "SCAN_LINK": "https://rinkeby.etherscan.io/token/0xc01a357137f188b3c3da0601b6a455a39961af9d",
    "NETWORK": {
      "NAME": "Rinkeby Testnet",
      "SYMBOL": "ETH",
      "ID": 4
    },
    "NFT_NAME": "TestNFT",
    "SYMBOL": "TEST",
    "MAX_SUPPLY": 500,
    "WEI_COST": 2500000000000000,
    "DISPLAY_COST": 0.0,
    "GAS_LIMIT": 100000,
    "MARKETPLACE": "OPENSEA",
    "MARKETPLACE_LINK": "https://opensea.io/collection/future-nft-mints-genesis-nft",
    "SHOW_BACKGROUND": false,
    "OTHER_NFT_LINK": "https://mint.futurenftmints.com"
    */

    "CONTRACT_ADDRESS": "0x5f66F72a4f87ec3Cd06241400bD2bA867F1233c7",
    "SCAN_LINK": "https://etherscan.io/token/0x5f66F72a4f87ec3Cd06241400bD2bA867F1233c7",  
    "NETWORK": {
      "NAME": "Mainnet",
      "SYMBOL": "ETH",
      "ID": 1
    },
    "NFT_NAME": "Future NFT Mints - Genesis NFT",
    "SYMBOL": "FNFTM0",
    "MAX_SUPPLY": 500,
    "WEI_COST": 250000000000000000,
    "DISPLAY_COST": 0.0,
    "GAS_LIMIT": 100000,
    "MARKETPLACE": "OPENSEA",
    "MARKETPLACE_LINK": "https://opensea.io/collection/future-nft-mints-genesis-nft",
    "SHOW_BACKGROUND": false,
    "OTHER_NFT_LINK": "https://app.futurenftmints.com/mint"
}
  