import React, { useEffect, useState } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../../actions';

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import PleaseSignIn from '../access/PleaseSignIn';

const LandingBenefitsCommunitySentimentInPalmOfYourHand = ({ auth, wallet, registeredUser }) => {

    const { account } = wallet; // use the account from the wallet store object

    if (account && !auth) return registeredUser.username ? <PleaseSignIn /> : <Redirect to="/register" />;

    return ( 
        <>
            {/*MOBILE*/}
            <div className="d-block d-lg-none">
                <Row style={{ paddingBottom: "50px", overflow:"hidden" }}>
                    <Col md={1}></Col>
                    <Col sm={12} lg={6} >       
                          
                        <Row style={{ paddingTop: "20px", color: "#ffffff", fontSize:"3em", lineHeight: "1em" }}>
                        <Col>Maximize Your Wallet</Col>
                        </Row>
                        <Row style={{ paddingTop: "20px" }}>
                        <Row style={{ paddingTop: "20px", color: "#ffffff", fontSize:"1.5em" }}>
                            <Col>
                                Manage all your NFTs in one place.
                            </Col>
                        </Row>
                        <Row style={{ paddingTop: "20px", color: "#ffffff", fontSize:"1em" }}>
                            <Col>
                                Receive notifications alerting you to NFT Benefits, Raffles, and Upcoming Mints. With Allow List Tracking and community-driven sentiment, you can do it all in one easy-to-use platform.
                                <br /><br />PLUS, earns rewards for contributing to our community.
                            </Col>
                        </Row>
                        <Row style={{ paddingTop: "40px", color: "#ffffff", fontSize:"1em" }}>
                            <Col>
                                0.05 ETH for an Annual Pass (mint is Oct 20)
                            </Col>
                        </Row>   
                        <Row style={{ paddingTop: "20px", color: "#ffffff", fontSize:"1em" }}>
                            <Col sm={4}>
                                <Link to="/all-nfts" style={{ textDecoration:"none"}}>
                                    <button className="btn btn-light mt-2" style={{height:"40px"}}>
                                        Try for free
                                    </button>
                                </Link>
                            </Col>
                            <Col sm={8}>
                                <a href="https://heymint.xyz/future-mints-annual" target="_BLANK" rel="noreferrer" style={{color:"#ffffff"}}>
                                    <button className="btn mt-2" style={{ backgroundColor: "#F83700", border: "#F83700", color:"#ffffff", height:"100%", height:"40px" }}>
                                        Signup for Allow List
                                    </button> 
                                </a>
                            </Col>
                        </Row>
                        <Row style={{ paddingTop: "30px", color: "#ffffff", fontSize:"0.7em" }}>
                            <Col>
                                <Link to='/signup' style={{color:"#ffffff"}}>
                                    Mint our Genesis NFT now!
                                </Link>
                            </Col>
                        </Row>
                        </Row>
                    </Col>            

                    <Col lg={5}>
                        <Row >
                        <div className="col-12" style={{ paddingTop: "30px"}}>
                            <img
                            alt=""
                            src="/images/mainLandingImage.png" 
                            width="700px"           
                            className=""
                            />
                        </div>
                        </Row>
                    </Col>
                </Row>

                <Row style={{ paddingTop: "20px", paddingBottom: "50px", overflow:"hidden" }}>                
                    <Col style={{fontSize:"1.5em", marginTop:"30px", marginBottom:"50px", color:"#ffffff", textAlign:"center"}}>                     
                        Free weekly NFT Giveaways. Win NFTs, Allow List spots, and more.
                        <div style={{ marginTop:"30px"}}>
                            <Link className="btn-lg btn-light" style={{ textDecoration:"none"}} to="/giveaways/live">Read the details</Link>
                        </div>
                    </Col>
                </Row>

                <Row style={{ paddingTop: "20px", overflow:"hidden" }}>
                    <hr style={{border: "0.5px solid #ffffff", width: "80%", margin:"auto"}}/>
                </Row>
                
                <Row style={{ paddingTop: "20px", paddingBottom: "50px", overflow:"hidden" }}>
                    <Col style={{marginTop:"30px", marginBottom:"50px", color:"#ffffff", textAlign:"center"}}>
                        <div className="row text-center">
                            <div className="col-3"></div>
                            <div className="col-6">
                                <span style={{fontSize:"1.25em"}}>We're proud to offer Future Mints' Premium tier access for FREE to members of these incredible NFT communities.</span>
                                <br /><br /><span style={{fontSize:"1em"}}>Want FREE Premium tier access for your NFT community? <a href="https://twitter.com/futurenftmints" target="_BLANK" rel="noreferrer" style={{color:"#ffffff"}}>Let us know.</a></span>
                            </div>
                        </div>                        
                    </Col>
                    <div className="col-12">
                        <div className="row text-center">
                            <div className="col-12 col-md-3 mb-5">
                                <Link to="/collection/Crypto-Packaged-Goods" style={{textDecoration:"none", color:"rgba(255, 255, 255, 0.8"}}>
                                    <img alt="Elliot Koss" src="https://fnftm-prod-images.s3.amazonaws.com/4bf9458268abdc3b80ce70ec95b626f5" width="200px" />
                                    <br />Crypto Packaged Goods
                                </Link>
                            </div>
                            <div className="col-12 col-md-3 mb-5">                            
                                <Link to="/collection/Club-CPG:-Pop" style={{textDecoration:"none", color:"rgba(255, 255, 255, 0.8"}}>
                                    <img alt="Elliot Koss" src="https://fnftm-prod-images.s3.amazonaws.com/57c33e4878b4689eefe61853237199a0" width="200px" />
                                    <br />CPG Pop
                                </Link>
                            </div>
                            <div className="col-12 col-md-3 mb-5">
                                <Link to="/collection/Floor:-Gen-3" style={{textDecoration:"none", color:"rgba(255, 255, 255, 0.8"}}>
                                    <img alt="Elliot Koss" src="https://fnftm-prod-images.s3.amazonaws.com/8233dd42f8ad925c5df3312ae1003d51" width="200px" />
                                    <br />Floor
                                </Link>
                            </div>
                            <div className="col-12 col-md-3 mb-5">
                                <Link to="/collection/Meta-Angels" style={{textDecoration:"none", color:"rgba(255, 255, 255, 0.8"}}>
                                    <img alt="Elliot Koss" src="https://fnftm-prod-images.s3.amazonaws.com/41fb56f245ea756493f251ba5f07b445" width="200px" />
                                    <br />Meta Angels
                                </Link>
                            </div>
                        </div>                        
                    </div>
                    <div className="col-12">
                        <div className="row text-center">
                            <div className="col-12 col-md-3 mb-5"></div>
                            <div className="col-12 col-md-3 mb-5">
                                <Link to="/collection/Nifty-Portal" style={{textDecoration:"none", color:"rgba(255, 255, 255, 0.8"}}>
                                    <img alt="Elliot Koss" src="https://fnftm-prod-images.s3.amazonaws.com/8b04dbc05ea447396c245c5ce6001947" width="200px" />
                                    <br />Nifty Portal
                                </Link>
                            </div>
                            <div className="col-12 col-md-3 mb-5">
                                <Link to="/collection/Champions:-Ascension" style={{textDecoration:"none", color:"rgba(255, 255, 255, 0.8"}}>
                                    <img alt="Elliot Koss" src="https://fnftm-prod-images.s3.amazonaws.com/429e7ae78b869a21a4080f4a8e6cb002" width="200px" />
                                    <br />Champions Ascension
                                </Link>
                            </div>
                        </div>                        
                    </div>
                </Row>

                <Row style={{textAlign:"center"}}>
                    <div className="col-2"></div>
                    <div className="col-8" style={{fontSize:"1.5em", marginTop:"30px", marginBottom:"50px", color:"#ffffff"}}>
                        We publish a FREE newsletter every weekday with useful industry news and an Insights Report covering an upcoming mint.
                        <div style={{ marginTop:"30px"}}>
                            <a className="btn-lg btn-light" style={{ textDecoration:"none"}} target={"_blank"} href="https://futurenftmints.substack.com/subscribe">Sign up now</a>
                        </div>
                    </div>
                </Row>   

                <Row style={{ paddingTop: "20px", paddingBottom: "50px", overflow:"hidden" }}>
                    <Col>
                        <Row style={{textAlign:"center"}}>
                        <hr style={{border: "0.5px solid #ffffff", width: "80%", margin:"auto"}}/>
                        <div style={{color:"#ffffff", paddingTop:"10px", fontSize:"0.7em"}}>
                            See what you can do with the Future NFT Mints platform
                            <br />
                            <div style={{fontSize:"1.3em", paddingTop:"10px"}}>&#9660;</div>
                        </div>
                        
                        </Row>
                    </Col>
                </Row>

                <Row style={{paddingBottom:"100px"}}>
                    <Col md={6} style={{margin:"auto", textAlign:"center"}}>
                        <Row>
                        <div className="col-md-3"></div>
                        <div className="col-md-9 col-12" style={{margin:"auto", textAlign:"center"}}>
                        <img
                            alt=""
                            src="/images/dashboard.png"               
                            width="100%"
                            className="shadow-lg mb-5 rounded border border-secondary"
                            />
                        </div>
                        </Row>
                    </Col>
                    <Col md={6} style={{margin:"auto"}}>
                        <Row className="pt-2">
                        <div className="col-md-2 col-1"></div>
                        <div className="col-md-1 col-2 text-right">
                            
                        </div>
                        <div className="col-8" style={{color:"#ffffff", fontSize:"1.5em", marginTop:"auto", marginBottom:"auto"}}>
                            One Simple Dashboard
                        </div>
                        </Row>
                        <Row className="pt-3">
                        <div className="col-3"></div>
                        <div className="col-md-6 col-8" style={{color:"#ffffff", fontSize:"0.9em", textAlign:"left"}}>
                            Future Mints makes it easy to see all of the key action items that you'd want to track in one place. Quickly see the Benefits your wallet makes you eligible for, all the Raffles in our platform, the Allow Lists that you're tracking, the number number of new upcoming mints, and more!
                        </div>
                        </Row>
                    </Col>
                </Row>

                <Row style={{paddingBottom:"100px"}}>
                    <Col md={6} className="order-2 order-md-1" style={{margin:"auto"}}>
                        <Row className="pt-2">
                        <div className="col-md-3 col-1"></div>
                        <div className="col-md-1 col-2 text-right">
                            
                        </div>
                        <div className="col-8" style={{color:"#ffffff", fontSize:"1.5em", marginTop:"auto", marginBottom:"auto"}}>
                            NFT Benefit Notifications
                        </div>
                        </Row>
                        <Row className="pt-3">
                        <div className="col-3 col-md-4"></div>
                        <div className="col-md-6 col-8" style={{color:"#ffffff", fontSize:"0.9em", textAlign:"left"}}>
                            Signup for your account, sit back, and relax. We'll send you a daily email with updates on your wallet's benefits. You're welcome to also come to our platform to see your NFT's benefits in your personalized feed, and you can see what benefits other NFTs offer to help inform your next purchase.
                        </div>                        
                        
                        </Row>
                    </Col>
                    <Col md={6} className="order-1 order-md-2" style={{margin:"auto", textAlign:"center"}}>
                        <Row>              
                        <div className="col-md-9 col-12" style={{margin:"auto", textAlign:"center"}}>
                        <img
                            alt=""
                            src="/images/benefitsFeed.png"               
                            width="100%"
                            className="shadow-lg mb-5 rounded border border-secondary"
                            />
                        </div>
                        <div className="col-md-3"></div>
                        </Row>
                    </Col>          
                </Row>                
                <Row style={{paddingBottom:"100px"}}>
                    <Col md={6} style={{margin:"auto", textAlign:"center"}}>
                        <Row>
                        <div className="col-md-3"></div>
                        <div className="col-md-9 col-12" style={{margin:"auto", textAlign:"center"}}>
                        <img
                            alt=""
                            src="/images/upcomingMints.png"               
                            width="100%"
                            className="shadow-lg mb-5 rounded border border-secondary"
                            />
                        </div>
                        </Row>
                    </Col>
                    <Col md={6} style={{margin:"auto"}}>
                        <Row className="pt-2">
                        <div className="col-md-2 col-1"></div>
                        <div className="col-md-1 col-2 text-right">
                            <img
                            alt=""
                            src="/images/stars.png" 
                            height="40px"              
                            
                            />
                        </div>
                        <div className="col-8" style={{color:"#ffffff", fontSize:"1.5em", marginTop:"auto", marginBottom:"auto"}}>
                            Upcoming Mints
                        </div>
                        </Row>
                        <Row className="pt-3">
                        <div className="col-3"></div>
                        <div className="col-md-6 col-8" style={{color:"#ffffff", fontSize:"0.9em", textAlign:"left"}}>
                            See up to 90 days of upcoming mints with our Premium version. Never miss a beat and be the first to find the next big project.
                        </div>
                        </Row>
                    </Col>
                </Row>                          
                <Row style={{paddingBottom:"100px"}}>
                    <Col md={6} className="order-2 order-md-1" style={{margin:"auto"}}>
                        <Row className="pt-2">
                        <div className="col-md-3 col-1"></div>
                        <div className="col-md-1 col-2 text-right">                            
                        </div>
                        <div className="col-8" style={{color:"#ffffff", fontSize:"1.5em", marginTop:"auto", marginBottom:"auto"}}>
                            Raffles Feed
                        </div>
                        </Row>
                        <Row className="pt-3">
                        <div className="col-3 col-md-4"></div>
                        <div className="col-md-6 col-8" style={{color:"#ffffff", fontSize:"0.9em", textAlign:"left"}}>
                            We aggregate raffles in one place to make it easier than ever to enter the Allow Lists you're looking for.
                        </div>
                        
                        </Row>
                    </Col>
                    <Col md={6} className="order-1 order-md-2" style={{margin:"auto", textAlign:"center"}}>
                        <Row>              
                        <div className="col-md-9 col-12" style={{margin:"auto", textAlign:"center"}}>
                        <img
                            alt=""
                            src="/images/rafflesFeed.png"               
                            width="100%"
                            className="shadow-lg mb-5 rounded border border-secondary"
                            />
                        </div>
                        <div className="col-md-3"></div>
                        </Row>
                    </Col>          
                </Row>                                
                <Row style={{paddingBottom:"100px"}}>
                    <Col md={6} style={{margin:"auto", textAlign:"center"}}>
                        <Row>
                        <div className="col-md-3"></div>
                        <div className="col-md-9 col-12" style={{margin:"auto", textAlign:"center"}}>
                        <img
                            alt=""
                            src="/images/communitySentiment.gif"               
                            width="100%"
                            className="shadow-lg mb-5 rounded border border-secondary"
                            />
                        </div>
                        </Row>
                    </Col>
                    <Col md={6} style={{margin:"auto"}}>
                        <Row className="pt-2">
                        <div className="col-md-2 col-1"></div>
                        <div className="col-md-1 col-2 text-right">
                            <img
                            alt=""
                            src="/images/lightbulb.png" 
                            height="40px"              
                            
                            />
                        </div>
                        <div className="col-8" style={{color:"#ffffff", fontSize:"1.5em", marginTop:"auto", marginBottom:"auto"}}>
                            Better Insights, Less Time
                        </div>
                        </Row>
                        <Row className="pt-3">
                            <div className="col-3"></div>
                            <div className="col-md-6 col-8" style={{color:"#ffffff", fontSize:"0.9em", textAlign:"left"}}>
                                Researching an NFT project is time-consuming. Features like upvoting, community sentiment votes, and info about upcoming mints or previous drops put the power of DYOR in your hands.
                            </div>
                        </Row>                        
                    </Col>
                </Row>
                <Row style={{paddingBottom:"50px"}}>
                    <Col md={6} className="order-2 order-md-1" style={{margin:"auto"}}>
                        <Row className="pt-2">
                        <div className="col-md-3 col-1"></div>
                        <div className="col-md-1 col-2 text-right">
                            <img
                            alt=""
                            src="/images/trophyHome.png" 
                            height="40px"              
                            
                            />
                        </div>
                        <div className="col-8" style={{color:"#ffffff", fontSize:"1.5em", marginTop:"auto", marginBottom:"auto"}}>
                            Contribute and Earn Rewards!
                        </div>
                        </Row>
                        <Row className="pt-3">
                        <div className="col-3 col-md-4"></div>
                        <div className="col-md-6 col-8" style={{color:"#ffffff", fontSize:"0.9em", textAlign:"left"}}>
                            Earn points by voting, sharing your opinions, and adding NFTs, Benefits, and Raffles. Move up our leaderboard to earn entries to our FREE Weekly Giveaway.
                        </div>
                        
                        </Row>
                    </Col>
                    <Col md={6} className="order-1 order-md-2" style={{margin:"auto", textAlign:"center"}}>
                        <Row>              
                        <div className="col-md-9 col-12" style={{margin:"auto", textAlign:"center"}}>
                        <img
                            alt=""
                            src="/images/leaderboard.png"               
                            width="100%"
                            className="shadow-lg mb-5 rounded border border-secondary"
                            />
                        </div>
                        <div className="col-md-3"></div>
                        </Row>
                    </Col>                              
                </Row>
                <Row>
                    <Col>
                        <a name="giveaways" id="giveaways"></a>
                        <Row style={{ paddingTop: "70px", paddingBottom: "50px", overflow:"hidden" }}>
                            <div className="col-12" style={{fontSize:"2em", marginTop:"30px", color:"#ffffff", textAlign:"center"}}>
                                Free Weekly NFT Giveaways
                            </div>                            
                            <div className="col-1"></div>
                            <div className="col-md-5" style={{fontSize:"1em", color:"#ffffff", marginTop:"30px", textAlign:"left"}}>
                                Every week, we're going to highlight upcoming mints, and ask that you vote on the ones you like the most. Future Mints will then try to mint as many of those NFTs as we can, and then give them away in multiple weekly giveaway raffles.
                                <br /><br />
                                You can hold onto your entries for as long as you'd like. If you want to wait until you have 100 entries and put them all towards one giveaway raffle, that's your decision to make.
                                <br /><br />10% of the Annual Pass NFT mint will help fund these giveaways, and we plan to partner with other NFTs to ensure our giveaway raffles are always exciting.
                            </div>
                            <div className="col-md-5" style={{fontSize:"1em", color:"#ffffff", marginTop:"30px", textAlign:"left"}}>
                                HOW TO EARN GIVEAWAY ENTRIES
                                <br /><br />1) Everyone who owns a Genesis (12 per day) and Annual Pass (2 per day) will stack entries for each Future Mints NFT they own in a connected wallet to their Future Mints account.
                                <br /><br />2) Earning points and being on the leaderboard each week will earn 1 entry. The Top 100 users each week will receive additional entries with #1 receiving 100, #2 receiving 99, and so on until #100 who will receive 1 entry on top of the 1 entry everyone with a point earns.
                            </div>
                        </Row>
                    </Col>
                </Row>

                

                <a name='team' id="team"></a>
                <Row style={{ textAlign:"center", paddingTop: "40px", paddingBottom:"20px", backgroundColor: "#29194A", color:"#ffffff", lineHeight: "1.25em" }}>
                    <div style={{ fontSize: "2em", paddingBottom:"40px" }}>
                        The Team
                    </div>          
                    <Col md={3}>
                        <div style={{ paddingTop:"20px"}}></div>
                        <img alt="Elliot Koss" src="/images/team/elliotkoss-founder-futurenftmints.png" width="200px" />
                        <br /><div style={{ fontSize: "1.25em"}}>Elliot Koss</div>
                        Founder
                        <br /><a href="https://twitter.com/elliotkoss" target="_BLANK"><img width="20" src="/images/twitter-logo.png" /></a>
                        &nbsp;&nbsp;<a href="https://linkedin.com/in/elliotkoss" target="_BLANK"><img width="20" src="/images/linkedin-logo.png" /></a>
                    </Col>                                               
                    <Col md={3}>
                        <div style={{ paddingTop:"20px"}}></div>
                        <img alt="Harrison Smith" src="/images/team/harrisonsmith-nft-researcher-futurenftmints.jpeg" width="200px" />
                        <br /><div style={{ fontSize: "1.25em"}}>Harrison Smith</div>
                        NFT Researcher
                        <br /><a href="https://twitter.com/hgrey0001" target="_BLANK"><img width="20" src="/images/twitter-logo.png" /></a>
                        &nbsp;&nbsp;<a href="https://www.linkedin.com/in/harrisongsmith/" target="_BLANK"><img width="20" src="/images/linkedin-logo.png" /></a>
                    </Col>                    
                    <Col md={3}>
                        <div style={{ paddingTop:"20px"}}></div>
                        <img alt="Grant Goodstein" src="/images/team/grantgoodstein.png" width="200px" />
                        <br /><div style={{ fontSize: "1.25em"}}>Grant Goodstein</div>
                        Partnerships
                        <br /><a href="https://twitter.com/1is1eth" target="_BLANK"><img width="20" src="/images/twitter-logo.png" /></a>
                    </Col>
                    <Col md={3}>
                        <div style={{ paddingTop:"20px"}}></div>
                        <img alt="Andrew Cosgrove" src="/images/team/andrewcosgrove-legaladvisor-futurenftmints.jpeg" width="200px" />
                        <br /><div style={{ fontSize: "1.25em"}}>Andrew Cosgrove</div>
                        Legal Advisor
                        <br /><a href="https://twitter.com/AndrewTCosgrove" target="_BLANK"><img width="20" src="/images/twitter-logo.png" /></a>
                        &nbsp;&nbsp;<a href="https://www.linkedin.com/in/andrew-cosgrove-26062784/" target="_BLANK"><img width="20" src="/images/linkedin-logo.png" /></a>
                    </Col>
                </Row>  
            </div>

            {/*DESKTOP*/}
            <div className="d-lg-block d-none">
                <Row style={{ paddingTop: "20px", paddingBottom: "50px", overflow:"hidden" }}>
                    <Col md={1}></Col>
                    <Col sm={12} lg={6} >       
                          
                        <Row style={{ paddingTop: "20px", color: "#ffffff", fontSize:"3em", lineHeight: "1em" }}>
                        <Col>Maximize Your Wallet</Col>
                        </Row>
                        <Row style={{ paddingTop: "20px" }}>
                        <Row style={{ paddingTop: "20px", color: "#ffffff", fontSize:"1.5em" }}>
                            <Col>
                                Manage all your NFTs in one place.
                            </Col>
                        </Row>
                        <Row style={{ paddingTop: "20px", color: "#ffffff", fontSize:"1em" }}>
                            <Col>
                                Receive notifications alerting you to NFT Benefits, Raffles, and Upcoming Mints. With Allow List Tracking and community-driven sentiment, you can do it all in one easy-to-use platform.
                                <br /><br />PLUS, earns rewards for contributing to our community.
                            </Col>
                        </Row>
                        <Row style={{ paddingTop: "40px", color: "#ffffff", fontSize:"1em" }}>
                            <Col>
                                0.05 ETH for an Annual Pass (mint is Oct 20)
                            </Col>
                        </Row>   
                        <Row style={{ paddingTop: "20px", color: "#ffffff", fontSize:"1em" }}>
                            <Col sm={4}>
                                <Link to="/all-nfts" style={{ textDecoration:"none"}}>
                                    <button className="btn btn-light mt-2" style={{height:"40px"}}>
                                        Try for free
                                    </button>
                                </Link>
                            </Col>
                            <Col sm={8}>
                                <a href="https://heymint.xyz/future-mints-annual" target="_BLANK" rel="noreferrer" style={{color:"#ffffff"}}>
                                    <button className="btn mt-2" style={{ backgroundColor: "#F83700", border: "#F83700", color:"#ffffff", height:"100%", height:"40px" }}>
                                        Signup for Allow List
                                    </button> 
                                </a>
                            </Col>
                        </Row>
                        <Row style={{ paddingTop: "30px", color: "#ffffff", fontSize:"0.7em" }}>
                            <Col>
                                <Link to='/signup' style={{color:"#ffffff"}}>
                                    Mint our Genesis NFT now!
                                </Link>
                            </Col>
                        </Row>
                        </Row>
                    </Col>            

                    <Col lg={5}>
                        <Row >
                        <div className="col-12" style={{ paddingTop: "30px"}}>
                            <img
                            alt=""
                            src="/images/mainLandingImage.png" 
                            width="700px"           
                            className=""
                            />
                        </div>
                        </Row>
                    </Col>
                </Row>

                <Row style={{ paddingTop: "20px", paddingBottom: "50px", overflow:"hidden" }}>                
                    <Col style={{fontSize:"1.5em", marginTop:"30px", marginBottom:"50px", color:"#ffffff", textAlign:"center"}}>                     
                        Free weekly NFT Giveaways. Win NFTs, Allow List spots, and more.
                        <div style={{ marginTop:"30px"}}>
                            <Link className="btn-lg btn-light" style={{ textDecoration:"none"}} to="/giveaways/live">Read the details</Link>
                        </div>
                    </Col>
                </Row>

                <Row style={{ paddingTop: "20px", overflow:"hidden" }}>
                    <hr style={{border: "0.5px solid #ffffff", width: "80%", margin:"auto"}}/>
                </Row>
                
                <Row style={{ paddingTop: "20px", paddingBottom: "50px", overflow:"hidden" }}>
                    <Col style={{marginTop:"30px", marginBottom:"50px", color:"#ffffff", textAlign:"center"}}>
                        <div className="row text-center">
                            <div className="col-3"></div>
                            <div className="col-6">
                                <span style={{fontSize:"1.25em"}}>We're proud to offer Future Mints' Premium tier access for FREE to members of these incredible NFT communities.</span>
                                <br /><br /><span style={{fontSize:"1em"}}>Want FREE Premium tier access for your NFT community? <a href="https://twitter.com/futurenftmints" target="_BLANK" rel="noreferrer" style={{color:"#ffffff"}}>Let us know.</a></span>
                            </div>
                        </div>                        
                    </Col>
                    <div className="col-12">
                        <div className="row text-center">
                            <div className="col-12 col-md-3 mb-5">
                                <Link to="/collection/Crypto-Packaged-Goods" style={{textDecoration:"none", color:"rgba(255, 255, 255, 0.8"}}>
                                    <img alt="Elliot Koss" src="https://fnftm-prod-images.s3.amazonaws.com/4bf9458268abdc3b80ce70ec95b626f5" width="200px" />
                                    <br />Crypto Packaged Goods
                                </Link>
                            </div>
                            <div className="col-12 col-md-3 mb-5">                            
                                <Link to="/collection/Club-CPG:-Pop" style={{textDecoration:"none", color:"rgba(255, 255, 255, 0.8"}}>
                                    <img alt="Elliot Koss" src="https://fnftm-prod-images.s3.amazonaws.com/57c33e4878b4689eefe61853237199a0" width="200px" />
                                    <br />CPG Pop
                                </Link>
                            </div>
                            <div className="col-12 col-md-3 mb-5">
                                <Link to="/collection/Floor:-Gen-3" style={{textDecoration:"none", color:"rgba(255, 255, 255, 0.8"}}>
                                    <img alt="Elliot Koss" src="https://fnftm-prod-images.s3.amazonaws.com/8233dd42f8ad925c5df3312ae1003d51" width="200px" />
                                    <br />Floor
                                </Link>
                            </div>
                            <div className="col-12 col-md-3 mb-5">
                                <Link to="/collection/Meta-Angels" style={{textDecoration:"none", color:"rgba(255, 255, 255, 0.8"}}>
                                    <img alt="Elliot Koss" src="https://fnftm-prod-images.s3.amazonaws.com/41fb56f245ea756493f251ba5f07b445" width="200px" />
                                    <br />Meta Angels
                                </Link>
                            </div>
                        </div>                        
                    </div>
                    <div className="col-12">
                        <div className="row text-center">
                            <div className="col-12 col-md-3 mb-5"></div>
                            <div className="col-12 col-md-3 mb-5">
                                <Link to="/collection/Nifty-Portal" style={{textDecoration:"none", color:"rgba(255, 255, 255, 0.8"}}>
                                    <img alt="Elliot Koss" src="https://fnftm-prod-images.s3.amazonaws.com/8b04dbc05ea447396c245c5ce6001947" width="200px" />
                                    <br />Nifty Portal
                                </Link>
                            </div>
                            <div className="col-12 col-md-3 mb-5">
                                <Link to="/collection/Champions:-Ascension" style={{textDecoration:"none", color:"rgba(255, 255, 255, 0.8"}}>
                                    <img alt="Elliot Koss" src="https://fnftm-prod-images.s3.amazonaws.com/429e7ae78b869a21a4080f4a8e6cb002" width="200px" />
                                    <br />Champions Ascension
                                </Link>
                            </div>
                        </div>                        
                    </div>
                </Row>

                <Row style={{textAlign:"center"}}>
                    <div className="col-2"></div>
                    <div className="col-8" style={{fontSize:"1.5em", marginTop:"30px", marginBottom:"50px", color:"#ffffff"}}>
                        We publish a FREE newsletter every weekday with useful industry news and an Insights Report covering an upcoming mint.
                        <div style={{ marginTop:"30px"}}>
                            <a className="btn-lg btn-light" style={{ textDecoration:"none"}} target={"_blank"} href="https://futurenftmints.substack.com/subscribe">Sign up now</a>
                        </div>
                    </div>
                </Row>   

                <Row style={{ paddingTop: "20px", paddingBottom: "50px", overflow:"hidden" }}>
                    <Col>
                        <Row style={{textAlign:"center"}}>
                        <hr style={{border: "0.5px solid #ffffff", width: "80%", margin:"auto"}}/>
                        <div style={{color:"#ffffff", paddingTop:"10px", fontSize:"0.7em"}}>
                            See what you can do with the Future NFT Mints platform
                            <br />
                            <div style={{fontSize:"1.3em", paddingTop:"10px"}}>&#9660;</div>
                        </div>
                        
                        </Row>
                    </Col>
                </Row>

                <Row style={{paddingBottom:"100px"}}>
                    <Col md={6} style={{margin:"auto", textAlign:"center"}}>
                        <Row>
                        <div className="col-md-3"></div>
                        <div className="col-md-9 col-12" style={{margin:"auto", textAlign:"center"}}>
                        <img
                            alt=""
                            src="/images/dashboard.png"               
                            width="100%"
                            className="shadow-lg mb-5 rounded border border-secondary"
                            />
                        </div>
                        </Row>
                    </Col>
                    <Col md={6} style={{margin:"auto"}}>
                        <Row className="pt-2">
                        <div className="col-md-2 col-1"></div>
                        <div className="col-md-1 col-2 text-right">
                            
                        </div>
                        <div className="col-8" style={{color:"#ffffff", fontSize:"1.5em", marginTop:"auto", marginBottom:"auto"}}>
                            One Simple Dashboard
                        </div>
                        </Row>
                        <Row className="pt-3">
                        <div className="col-3"></div>
                        <div className="col-md-6 col-8" style={{color:"#ffffff", fontSize:"0.9em", textAlign:"left"}}>
                            Future Mints makes it easy to see all of the key action items that you'd want to track in one place. Quickly see the Benefits your wallet makes you eligible for, all the Raffles in our platform, the Allow Lists that you're tracking, the number number of new upcoming mints, and more!
                        </div>
                        </Row>
                    </Col>
                </Row>

                <Row style={{paddingBottom:"100px"}}>
                    <Col md={6} className="order-2 order-md-1" style={{margin:"auto"}}>
                        <Row className="pt-2">
                        <div className="col-md-3 col-1"></div>
                        <div className="col-md-1 col-2 text-right">
                            
                        </div>
                        <div className="col-8" style={{color:"#ffffff", fontSize:"1.5em", marginTop:"auto", marginBottom:"auto"}}>
                            NFT Benefit Notifications
                        </div>
                        </Row>
                        <Row className="pt-3">
                        <div className="col-3 col-md-4"></div>
                        <div className="col-md-6 col-8" style={{color:"#ffffff", fontSize:"0.9em", textAlign:"left"}}>
                            Signup for your account, sit back, and relax. We'll send you a daily email with updates on your wallet's benefits. You're welcome to also come to our platform to see your NFT's benefits in your personalized feed, and you can see what benefits other NFTs offer to help inform your next purchase.
                        </div>                        
                        
                        </Row>
                    </Col>
                    <Col md={6} className="order-1 order-md-2" style={{margin:"auto", textAlign:"center"}}>
                        <Row>              
                        <div className="col-md-9 col-12" style={{margin:"auto", textAlign:"center"}}>
                        <img
                            alt=""
                            src="/images/benefitsFeed.png"               
                            width="100%"
                            className="shadow-lg mb-5 rounded border border-secondary"
                            />
                        </div>
                        <div className="col-md-3"></div>
                        </Row>
                    </Col>          
                </Row>                
                <Row style={{paddingBottom:"100px"}}>
                    <Col md={6} style={{margin:"auto", textAlign:"center"}}>
                        <Row>
                        <div className="col-md-3"></div>
                        <div className="col-md-9 col-12" style={{margin:"auto", textAlign:"center"}}>
                        <img
                            alt=""
                            src="/images/upcomingMints.png"               
                            width="100%"
                            className="shadow-lg mb-5 rounded border border-secondary"
                            />
                        </div>
                        </Row>
                    </Col>
                    <Col md={6} style={{margin:"auto"}}>
                        <Row className="pt-2">
                        <div className="col-md-2 col-1"></div>
                        <div className="col-md-1 col-2 text-right">
                            <img
                            alt=""
                            src="/images/stars.png" 
                            height="40px"              
                            
                            />
                        </div>
                        <div className="col-8" style={{color:"#ffffff", fontSize:"1.5em", marginTop:"auto", marginBottom:"auto"}}>
                            Upcoming Mints
                        </div>
                        </Row>
                        <Row className="pt-3">
                        <div className="col-3"></div>
                        <div className="col-md-6 col-8" style={{color:"#ffffff", fontSize:"0.9em", textAlign:"left"}}>
                            See up to 90 days of upcoming mints with our Premium version. Never miss a beat and be the first to find the next big project.
                        </div>
                        </Row>
                    </Col>
                </Row>                          
                <Row style={{paddingBottom:"100px"}}>
                    <Col md={6} className="order-2 order-md-1" style={{margin:"auto"}}>
                        <Row className="pt-2">
                        <div className="col-md-3 col-1"></div>
                        <div className="col-md-1 col-2 text-right">                            
                        </div>
                        <div className="col-8" style={{color:"#ffffff", fontSize:"1.5em", marginTop:"auto", marginBottom:"auto"}}>
                            Raffles Feed
                        </div>
                        </Row>
                        <Row className="pt-3">
                        <div className="col-3 col-md-4"></div>
                        <div className="col-md-6 col-8" style={{color:"#ffffff", fontSize:"0.9em", textAlign:"left"}}>
                            We aggregate raffles in one place to make it easier than ever to enter the Allow Lists you're looking for.
                        </div>
                        
                        </Row>
                    </Col>
                    <Col md={6} className="order-1 order-md-2" style={{margin:"auto", textAlign:"center"}}>
                        <Row>              
                        <div className="col-md-9 col-12" style={{margin:"auto", textAlign:"center"}}>
                        <img
                            alt=""
                            src="/images/rafflesFeed.png"               
                            width="100%"
                            className="shadow-lg mb-5 rounded border border-secondary"
                            />
                        </div>
                        <div className="col-md-3"></div>
                        </Row>
                    </Col>          
                </Row>                                
                <Row style={{paddingBottom:"100px"}}>
                    <Col md={6} style={{margin:"auto", textAlign:"center"}}>
                        <Row>
                        <div className="col-md-3"></div>
                        <div className="col-md-9 col-12" style={{margin:"auto", textAlign:"center"}}>
                        <img
                            alt=""
                            src="/images/communitySentiment.gif"               
                            width="100%"
                            className="shadow-lg mb-5 rounded border border-secondary"
                            />
                        </div>
                        </Row>
                    </Col>
                    <Col md={6} style={{margin:"auto"}}>
                        <Row className="pt-2">
                        <div className="col-md-2 col-1"></div>
                        <div className="col-md-1 col-2 text-right">
                            <img
                            alt=""
                            src="/images/lightbulb.png" 
                            height="40px"              
                            
                            />
                        </div>
                        <div className="col-8" style={{color:"#ffffff", fontSize:"1.5em", marginTop:"auto", marginBottom:"auto"}}>
                            Better Insights, Less Time
                        </div>
                        </Row>
                        <Row className="pt-3">
                            <div className="col-3"></div>
                            <div className="col-md-6 col-8" style={{color:"#ffffff", fontSize:"0.9em", textAlign:"left"}}>
                                Researching an NFT project is time-consuming. Features like upvoting, community sentiment votes, and info about upcoming mints or previous drops put the power of DYOR in your hands.
                            </div>
                        </Row>                        
                    </Col>
                </Row>
                <Row style={{paddingBottom:"50px"}}>
                    <Col md={6} className="order-2 order-md-1" style={{margin:"auto"}}>
                        <Row className="pt-2">
                        <div className="col-md-3 col-1"></div>
                        <div className="col-md-1 col-2 text-right">
                            <img
                            alt=""
                            src="/images/trophyHome.png" 
                            height="40px"              
                            
                            />
                        </div>
                        <div className="col-8" style={{color:"#ffffff", fontSize:"1.5em", marginTop:"auto", marginBottom:"auto"}}>
                            Contribute and Earn Rewards!
                        </div>
                        </Row>
                        <Row className="pt-3">
                        <div className="col-3 col-md-4"></div>
                        <div className="col-md-6 col-8" style={{color:"#ffffff", fontSize:"0.9em", textAlign:"left"}}>
                            Earn points by voting, sharing your opinions, and adding NFTs, Benefits, and Raffles. Move up our leaderboard to earn entries to our FREE Weekly Giveaway.
                        </div>
                        
                        </Row>
                    </Col>
                    <Col md={6} className="order-1 order-md-2" style={{margin:"auto", textAlign:"center"}}>
                        <Row>              
                        <div className="col-md-9 col-12" style={{margin:"auto", textAlign:"center"}}>
                        <img
                            alt=""
                            src="/images/leaderboard.png"               
                            width="100%"
                            className="shadow-lg mb-5 rounded border border-secondary"
                            />
                        </div>
                        <div className="col-md-3"></div>
                        </Row>
                    </Col>                              
                </Row>
                <Row>
                    <Col>
                        <a name="giveaways" id="giveaways"></a>
                        <Row style={{ paddingTop: "70px", paddingBottom: "50px", overflow:"hidden" }}>
                            <div className="col-12" style={{fontSize:"2em", marginTop:"30px", color:"#ffffff", textAlign:"center"}}>
                                Free Weekly NFT Giveaways
                            </div>                            
                            <div className="col-1"></div>
                            <div className="col-md-5" style={{fontSize:"1em", color:"#ffffff", marginTop:"30px", textAlign:"left"}}>
                                Every week, we're going to highlight upcoming mints, and ask that you vote on the ones you like the most. Future Mints will then try to mint as many of those NFTs as we can, and then give them away in multiple weekly giveaway raffles.
                                <br /><br />
                                You can hold onto your entries for as long as you'd like. If you want to wait until you have 100 entries and put them all towards one giveaway raffle, that's your decision to make.
                                <br /><br />10% of the Annual Pass NFT mint will help fund these giveaways, and we plan to partner with other NFTs to ensure our giveaway raffles are always exciting.
                            </div>
                            <div className="col-md-5" style={{fontSize:"1em", color:"#ffffff", marginTop:"30px", textAlign:"left"}}>
                                HOW TO EARN GIVEAWAY ENTRIES
                                <br /><br />1) Everyone who owns a Genesis (12 per day) and Annual Pass (2 per day) will stack entries for each Future Mints NFT they own in a connected wallet to their Future Mints account.
                                <br /><br />2) Earning points and being on the leaderboard each week will earn 1 entry. The Top 100 users each week will receive additional entries with #1 receiving 100, #2 receiving 99, and so on until #100 who will receive 1 entry on top of the 1 entry everyone with a point earns.
                            </div>
                        </Row>
                    </Col>
                </Row>

                

                <a name='team' id="team"></a>
                <Row style={{ textAlign:"center", paddingTop: "40px", paddingBottom:"20px", backgroundColor: "#29194A", color:"#ffffff", lineHeight: "1.25em" }}>
                    <div style={{ fontSize: "2em", paddingBottom:"40px" }}>
                        The Team
                    </div>          
                    <Col md={3}>
                        <div style={{ paddingTop:"20px"}}></div>
                        <img alt="Elliot Koss" src="/images/team/elliotkoss-founder-futurenftmints.png" width="200px" />
                        <br /><div style={{ fontSize: "1.25em"}}>Elliot Koss</div>
                        Founder
                        <br /><a href="https://twitter.com/elliotkoss" target="_BLANK"><img width="20" src="/images/twitter-logo.png" /></a>
                        &nbsp;&nbsp;<a href="https://linkedin.com/in/elliotkoss" target="_BLANK"><img width="20" src="/images/linkedin-logo.png" /></a>
                    </Col>                                               
                    <Col md={3}>
                        <div style={{ paddingTop:"20px"}}></div>
                        <img alt="Harrison Smith" src="/images/team/harrisonsmith-nft-researcher-futurenftmints.jpeg" width="200px" />
                        <br /><div style={{ fontSize: "1.25em"}}>Harrison Smith</div>
                        NFT Researcher
                        <br /><a href="https://twitter.com/hgrey0001" target="_BLANK"><img width="20" src="/images/twitter-logo.png" /></a>
                        &nbsp;&nbsp;<a href="https://www.linkedin.com/in/harrisongsmith/" target="_BLANK"><img width="20" src="/images/linkedin-logo.png" /></a>
                    </Col>                    
                    <Col md={3}>
                        <div style={{ paddingTop:"20px"}}></div>
                        <img alt="Grant Goodstein" src="/images/team/grantgoodstein.png" width="200px" />
                        <br /><div style={{ fontSize: "1.25em"}}>Grant Goodstein</div>
                        Partnerships
                        <br /><a href="https://twitter.com/1is1eth" target="_BLANK"><img width="20" src="/images/twitter-logo.png" /></a>
                    </Col>
                    <Col md={3}>
                        <div style={{ paddingTop:"20px"}}></div>
                        <img alt="Andrew Cosgrove" src="/images/team/andrewcosgrove-legaladvisor-futurenftmints.jpeg" width="200px" />
                        <br /><div style={{ fontSize: "1.25em"}}>Andrew Cosgrove</div>
                        Legal Advisor
                        <br /><a href="https://twitter.com/AndrewTCosgrove" target="_BLANK"><img width="20" src="/images/twitter-logo.png" /></a>
                        &nbsp;&nbsp;<a href="https://www.linkedin.com/in/andrew-cosgrove-26062784/" target="_BLANK"><img width="20" src="/images/linkedin-logo.png" /></a>
                    </Col>
                </Row>  
            </div>
            
            
        </>
    );
};

function mapStateToProps({ auth, wallet, registeredUser }) {
    return { auth, wallet, registeredUser };
}

export default connect(mapStateToProps, actions)(LandingBenefitsCommunitySentimentInPalmOfYourHand);
