import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect, useLocation } from 'react-router-dom';
import axios from 'axios';

import useTokenGatedAccess from '../../../hooks/useTokenGatedAccess';

// components
import PleaseConnectWallet from '../../access/PleaseConnectWallet';
import PleaseWait from '../../access/PleaseWait';
import PleaseRegister from '../../access/PleaseRegister';
import NoAccessPublic from '../../access/NoAccessPublic';
import PleaseSignIn from '../../access/PleaseSignIn';
import AccessDenied from '../../access/AccessDenied';
import BenefitsFeed from '../BenefitsFeed';

const UserBenefitsMain = ({ auth, wallet, registeredUser }) => {    

    const { account } = wallet; // use the account from the wallet store object

    // Pass the account to the useTokenGatedAccess hook to determin if this account has access
    // The useTokenGatedAccess hook handles an undefined account
    const { access, _walletAccessTokens } = useTokenGatedAccess(account); // eslint-disable-line no-unused-vars  

    const [userBenefits, setUserBenefits] = useState([]);
    const [publicUser, setPublicUser] = useState(false);        

    let urlParams = useLocation().search;
    let apiFetchParams = urlParams;

    let initialValues, keyValues, sort, redeemed;

    if (urlParams != '') {        
        let initialValuesStartSplit = urlParams.split('?');
        let initialValuesKeyValues = initialValuesStartSplit[1].split('&');

        for ( let i = 0; i < initialValuesKeyValues.length ; ++i ) {
            keyValues = initialValuesKeyValues[i].split('=');
            
            if (keyValues[0] === 'sort') {
                sort = keyValues[1];
            }

            if (keyValues[0] === 'redeemed') {
                redeemed = keyValues[1];
            }
        }
        
        initialValues = { 
            'sort':             sort,
            'redeemed':         redeemed
        }        

        apiFetchParams += '&feed=true';
    }
    else {
        initialValues = { };
        apiFetchParams += '?feed=true';
    }

    useEffect(() => {
        const fetchUserBenefits = async () => {
            const response = await axios.get(`/api/get/alerts${apiFetchParams}`, {
                validateStatus: function (status) {
                    return status < 500; // Resolve only if the status code is less than 500
                }
            });
            setUserBenefits(response.data);
        }
        if ( auth ) {
            fetchUserBenefits();
        }
    }, [auth])
    
    //this avoids a flicker where the public info is shown just before the logged in
    //user's info is recognized which changes the info on the page to the appropriate details
    const delay = ms => new Promise(res => setTimeout(res, ms));
    const delayPublic = async () => {
        await delay(2000);
        //console.log("Waited 5s");
        setPublicUser(true);
      };
    delayPublic();

    const renderUserBenefits = () => {
        if (!account && publicUser) {
            return <Redirect to="/signup" />
        }           
        switch(access) { 
            case null:                
                return <PleaseWait />;
            case false:                              
                if (!auth) return registeredUser.username ? <PleaseSignIn /> : <Redirect to="/register" />;
                if (registeredUser.account_type === 'top 100') {                                        
                    return ( <BenefitsFeed benefits={userBenefits} page="user" urlParams={urlParams} initialValues={initialValues} />  ); 
                }                    
                else if (registeredUser.username) {                                        
                    return ( <BenefitsFeed benefits={userBenefits} page="user" urlParams={urlParams} initialValues={initialValues} />  ); 
                }
                return <p style={{ color: "white" }}>Something went wrong. Try disconnecting your wallet and refresh the page.</p>;
            case true:                
                if (!auth) return registeredUser.username ? <PleaseSignIn /> : <Redirect to="/register" />;          
                if (registeredUser.username) {                    
                    return ( <BenefitsFeed benefits={userBenefits} page="user" urlParams={urlParams} initialValues={initialValues} />  ); 
                }                
                else { 
                    return (  <Redirect to="/register" />  );    
                }
            default:
                return <p style={{ color: "white" }}>Something went wrong. Do you <a href="/mint">have a token</a>?</p>;
        }
    }

    return (
        <div >                        
            {/* In lieu of a redirect, provide a link to the register page. */}
            {/* Otherwise, while the username is temporarily unavailable, a registered user can */}
            {/* be redirected to the Register page, and the Register page does not redirect back to the Calendar. */}
            {/* If it did, there is a risk of infinite redirects */}
            { renderUserBenefits() }
        </div>
    );
};

function mapStateToProps({ auth, wallet, registeredUser }) {
    return { auth, wallet, registeredUser };
}

export default connect(mapStateToProps)(UserBenefitsMain);
