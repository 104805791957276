import { useState, useMemo, useEffect } from 'react';
import { useLocation, useParams, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import axios from 'axios';

import NFTCalendar from './NFTCalendar';
import Pagination from '../pagination/Pagination';
import CalendarHeader from './CalendarHeader';
import CalendarFiltersForm from './CalendarFiltersForm';

let PageSize = 10;


const Calendar = ({ calendarItems, access, registeredUsername }) => {    
    const url = "/api/mintCalendar";
    let urlParams = useLocation().search;
    const [params, setParams] = useState(url + urlParams);
    const [calendarItemsNew, setCalendarItemsNew] = useState( calendarItems );    
    const [totalCount, setTotalCount] = useState( calendarItems.length );
    const [initialURLParams, setInitialURLParams] = useState(true);
    const [filters, setFilters] = useState('');
    const [showFilters, setShowFilters] = useState('hide');
    
    
    useEffect(() => {
        if (urlParams != '' && !!initialURLParams){
            setParams(url + urlParams);
            setInitialURLParams(false);
            setFilters(urlParams);
            setShowFilters('show');
        }
        else if (urlParams == '' && !!initialURLParams) {
            setFilters('');            
        }
        const fetchCalendarItems = async () => {
            const response = await axios.get(params, {
                validateStatus: function (status) {
                    return status < 500; // Resolve only if the status code is less than 500
                }
            });
            setCalendarItemsNew(response.data);
            setTotalCount(response.data.length);
        }
        fetchCalendarItems()
    }, [params]);




    const thisPage = ( useParams().page * 1) || 1;
    const [currentPage, setCurrentPage] = useState(thisPage);   

    let pageData = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;        
        if (calendarItemsNew == '' && params === '/api/mintCalendar' ) { return calendarItems.slice(firstPageIndex, lastPageIndex); }
        else { return calendarItemsNew.slice(firstPageIndex, lastPageIndex); }
    }, [currentPage, calendarItems, calendarItemsNew]); 
    
    //console.log(pageData);
    let initialValues, keyValues, sort, blockchain, roadmapCategories, startDate, endDate, priceMin, priceMax, supplyMin, supplyMax, twitterMin, twitterMax, discordMin, discordMax, watchlist, haveAL, wantAL, search;

    if (urlParams != '') {        
        let initialValuesStartSplit = urlParams.split('?');
        let initialValuesKeyValues = initialValuesStartSplit[1].split('&');

        for ( let i = 0; i < initialValuesKeyValues.length ; ++i ) {
            keyValues = initialValuesKeyValues[i].split('=');
            
            if (keyValues[0] === 'sort') {
                sort = keyValues[1];
            }

            if (keyValues[0] === 'blockchain') {
                blockchain = keyValues[1];
            }

            if (keyValues[0] === 'roadmapCategories') {
                blockchain = keyValues[1];
            }

            if (keyValues[0] === 'startDate') {
                startDate = keyValues[1];
            }

            if (keyValues[0] === 'endDate') {
                endDate = keyValues[1];
            }

            if (keyValues[0] === 'priceMin') {
                priceMin = keyValues[1];
            }

            if (keyValues[0] === 'priceMax') {
                priceMax = keyValues[1];
            }

            if (keyValues[0] === 'supplyMin') {
                supplyMin = keyValues[1];
            }

            if (keyValues[0] === 'supplyMax') {
                supplyMax = keyValues[1];
            }

            if (keyValues[0] === 'twitterMin') {
                twitterMin = keyValues[1];
            }

            if (keyValues[0] === 'twitterMax') {
                twitterMax = keyValues[1];
            }

            if (keyValues[0] === 'discordMin') {
                discordMin = keyValues[1];
            }

            if (keyValues[0] === 'discordMax') {
                discordMax = keyValues[1];
            }

            if (keyValues[0] === 'watchlist') {
                watchlist = keyValues[1];
            }

            if (keyValues[0] === 'haveAL') {
                haveAL = keyValues[1];
            }

            if (keyValues[0] === 'wantAL') {
                wantAL = keyValues[1];
            }

            if (keyValues[0] === 'search') {
                search = keyValues[1];
            }
        }
        
        initialValues = { 
            'sort':             sort,
            'blockchain':       [],
            //'roadmapCategories':[],
            'startDate':        startDate,
            'endDate':          endDate,
            'priceMin':         priceMin,
            'priceMax':         priceMax,
            'supplyMin':        supplyMin,
            'supplyMax':        supplyMax,
            'twitterMin':       twitterMin,
            'twitterMax':       twitterMax,
            'discordMin':       discordMin,
            'discordMax':       discordMax,
            'watchlist':        watchlist,
            'haveAL':           haveAL,
            'wantAL':           wantAL

        }

        if ( search !== undefined ) {
            initialValues.search= search;
        }
        if ( blockchain !== undefined ) {
            initialValues.blockchain = blockchain.split('%2C');
        }
        // if ( roadmapCategories !== undefined ) {
        //     initialValues.roadmapCategories = roadmapCategories.split('%2C');
        // }
    }
    else {
        initialValues = { }
        initialValues.blockchain = []
        //initialValues.roadmapCategories = []
    }

    const noSearchResults = () => {
        return <>
        <div className="row text-center" style={{ marginTop: "10px", marginBottom: "10px" }}>
            <div className="col-lg-12 col-sm-3 col-12">
                No results
            </div>                        
        </div>
        <div className="row text-center" style={{ marginTop: "10px", marginBottom: "10px" }}>
            <div className="col-lg-12 col-sm-3 col-12">
                <a href="/calendar" style={{ color: "#ffffff", verticalAlign: "baseline" }}>
                    Reset
                </a>
            </div>                        
        </div>
        </>;
    }


    const renderCalendarFilters = () => {
        return <>
                        
                <div className="row" style={{ marginTop:"20px"}}>
                    <CalendarFiltersForm 
                        initialValues={initialValues}
                        access={access}
                        onCalendarFilterSubmit
                        onSubmit={
                            async (values) => {
                                // console.log('values:' + JSON.stringify(values.blockchain))
                                try {
                                    //const { url } = await fetch('/s3Url').then(res => res.json())
                                    //console.log(url)
                                    // https://axios-http.com/docs/instance
                                    // axios#post(url[, data[, config]])

                                    // console.log("Values: " + values);
                                    let paramsNew = url + '?';
                                    
                                    if (values.search){
                                        if ( paramsNew != (url + '?' ) ) {
                                            paramsNew += '&';
                                        }
                                        paramsNew += 'search=' + values.search;
                                    }

                                    if (values.sort){
                                        if ( paramsNew != (url + '?' ) ) {
                                            paramsNew += '&';
                                        }
                                        paramsNew += 'sort=' + values.sort;
                                    }

                                    if (values.startDate) {
                                        if ( paramsNew != (url + '?' ) ) {
                                            paramsNew += '&';
                                        }
                                        paramsNew += 'startDate=' + values.startDate;
                                    }

                                    if (values.endDate) {
                                        if ( paramsNew != (url + '?' ) ) {
                                            paramsNew += '&';
                                        }
                                        paramsNew += 'endDate=' + values.endDate;
                                    }
                                    
                                    if (values.blockchain) {
                                            let blockchainParams = values.blockchain
                                            if (blockchainParams[0] !== ('' || undefined)){
                                                if ( paramsNew != (url + '?' ) ) {
                                                    paramsNew += '&';
                                                }
                                                paramsNew += 'blockchain=' + blockchainParams[0]
                                                for (let i = 1; i < blockchainParams.length; i++){
                                                    paramsNew += "%2C" + blockchainParams[i]
                                                }
                                        }
                                    };

                                    // if (values.roadmapCategories) {
                                    //     let roadmapParams = values.roadmapCategories
                                    //     if (roadmapParams[0] != ('' || undefined)){
                                    //         if ( paramsNew != (url + '?' ) ) {
                                    //             paramsNew += '&';
                                    //         }
                                    //         paramsNew += 'roadmapCategories=' + roadmapParams[0]
                                    //         for (let i = 1; i < roadmapParams.length; i++){
                                    //             paramsNew += "%2C" + roadmapParams[i]
                                    //         }
                                    //     }
                                    // };

                                    if ( values.priceMin ) {
                                        if ( paramsNew != (url + '?' ) ) {
                                            paramsNew += '&';
                                        }
                                        paramsNew += 'priceMin=' + values.priceMin;
                                    };

                                    if ( values.priceMax ) {
                                        if ( paramsNew != (url + '?' ) ) {
                                            paramsNew += '&';
                                        }
                                        paramsNew += 'priceMax=' + values.priceMax;
                                    };

                                    if ( values.supplyMin ) {
                                        if ( paramsNew != (url + '?' ) ) {
                                            paramsNew += '&';
                                        }
                                        paramsNew += 'supplyMin=' + values.supplyMin;
                                    };

                                    if ( values.supplyMax ) {
                                        if ( paramsNew != (url + '?' ) ) {
                                            paramsNew += '&';
                                        }
                                        paramsNew += 'supplyMax=' + values.supplyMax;
                                    };

                                    if ( values.twitterMin ) {
                                        if ( paramsNew != (url + '?' ) ) {
                                            paramsNew += '&';
                                        }
                                        paramsNew += 'twitterMin=' + values.twitterMin;
                                    };

                                    if ( values.twitterMax ) {
                                        if ( paramsNew != (url + '?' ) ) {
                                            paramsNew += '&';
                                        }
                                        paramsNew += 'twitterMax=' + values.twitterMax;
                                    };

                                    if ( values.discordMin ) {
                                        if ( paramsNew != (url + '?' ) ) {
                                            paramsNew += '&';
                                        }
                                        paramsNew += 'discordMin=' + values.discordMin;
                                    };

                                    if ( values.discordMax ) {
                                        if ( paramsNew != (url + '?' ) ) {
                                            paramsNew += '&';
                                        }
                                        paramsNew += 'discordMax=' + values.discordMax;
                                    };

                                    if ( values.watchlist ) {
                                        if ( paramsNew != (url + '?' ) ) {
                                            paramsNew += '&';
                                        }
                                        paramsNew += 'watchlist=' + values.watchlist;
                                    };
                                    
                                    if ( values.haveAL ) {
                                        if ( paramsNew != (url + '?' ) ) {
                                            paramsNew += '&';
                                        }
                                        paramsNew += 'haveAL=' + values.haveAL;
                                    };

                                    if ( values.wantAL ) {
                                        if ( paramsNew != (url + '?' ) ) {
                                            paramsNew += '&';
                                        }
                                        paramsNew += 'wantAL=' + values.wantAL;
                                    };                                    
                                    
                                    setParams(paramsNew);
                                    var split = paramsNew.split("?");
                                    split.shift();
                                    setFilters('?' + split.join(''));
                                    
                                    window.location.assign(paramsNew.replace('/api/mintCalendar', '/calendar'));
                                    
                                } catch (error) {
                                    // This should be visible in FS and (hopefully) alert FS of the issue
                                    console.error(error.response)
                                }
                            }
                        }
                    />
                </div>
            
        </>;
    }

    const showFiltersButton = (showFilters) => {
        if ( showFilters === 'show' ) {
            return <>
                <div className="row text-center" style={{fontSize:"1.25em"}}>
                    <div style={{textDecoration:"underline"}} 
                        onClick={async () => {                    
                            setShowFilters('hide');                     
                        }}>Hide filters
                    </div>
                </div>
                {renderCalendarFilters()}                
            </>;
        }
        else if ( showFilters === 'hide' ) {
            return <>
                <div className="row text-center" style={{fontSize:"1.25em"}}>
                    <div style={{textDecoration:"underline"}} 
                        onClick={async () => {                    
                            setShowFilters('show');                     
                        }}>Show filters
                    </div>
                </div>
            </>;
        }
    }
    
    return (
        <>
        <CalendarHeader currentPage={currentPage} access={access} />
        
        <div className="row" style={{marginTop:"0px"}}>
            <div className="col-lg-2 col-12 d-sm-block d-none">
                <div style={{ color: "#ffffff", padding:"10px"}}>
                    {renderCalendarFilters()}
                </div>
            </div>
            <div className="col-lg-2 col-12 d-xs-block d-sm-none">
                <div style={{ color: "#ffffff", padding:"10px"}}>
                    {showFiltersButton(showFilters)}
                </div>
            </div>
            <div className="col-xl-8 col-lg-10 col-12" style={{paddingRight:"25px", paddingLeft:"25px"}}>            
                {pageData.length > 0 ? pageData.map(item => <div key={item.id}><NFTCalendar calendarItem={item} access={access} registeredUsername={registeredUsername} /></div>) : noSearchResults()}
            </div>
            <div className="col-xl-2 col-12" style={{paddingRight:"25px", paddingLeft:"25px"}}>            
                <div className="row justify-content-around text-center" style={{ color: "#ffffff"}}>
                      
                    <div className="col-12 col-sm-4 col-xl-12 rounded py-2" style={{backgroundColor: "#0E1117", marginTop:"30px" }}>                        
                        <div className="row d-inline-block align-center">
                            Upgrade to Lifetime Premium access
                        </div>
                        <br />
                        <Link to="/mint" style={{ color:"#fff"}}>
                            <div className="d-inline-block align-center" style={{maxWidth:"200px"}}>
                                <img
                                    alt=""
                                    src="/images/fnftm-genesis-ad-sm.png"
                                    width="100%"                                
                                    className="d-inline-block align-center"
                                />
                            </div>
                        </Link>
                        <br /><Link to="/mint" style={{ color:"#fff"}}>Mint now</Link>
                    </div>

                    <div className="col-12 col-sm-4 col-xl-12 rounded py-2" style={{backgroundColor: "#0E1117", marginTop:"30px" }}>                        
                        <div className="row d-inline-block align-center" style={{fontSize:"1.25em"}}>
                            Free Newsletter
                        </div>
                        <div className="row align-left mt-2">
                            
                            <div>5 Days A Week</div>
                            <div>NFT Insights Report</div>
                            <div>Latest NFT News</div>                                
                            
                        </div>
                        <br />
                        <a href="https://futurenftmints.substack.com/subscribe" target="_blank" rel="noreferrer" style={{ color:"#fff"}}>
                        <button className="btn btn-sm btn-light" style={{width:"80%"}} >
                            Sign up now
                        </button>    
                        </a>
                    </div>
                </div>                
            </div>
        </div>
        
        <div className="row text-center" style={{marginTop:"20px"}}>
            <div className="col-lg-2"></div>
            <div className="col-lg-8 col-12">
                <Pagination
                className="pagination-bar"
                currentPage={currentPage}
                totalCount={totalCount}
                pageSize={PageSize}
                onPageChange={page => setCurrentPage(page)}
                filters={filters}
                />
            </div>            
        </div>
        
        </>
    );
};

function mapStateToProps({ form }) {
    return { form };
}

export default connect(mapStateToProps, actions)(Calendar);
