import 'bootstrap/dist/css/bootstrap.min.css';
import React, { Component } from 'react';
import { BrowserRouter, Route, Redirect, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../actions';

import ScrollToTop from "./ScrollToTop";

//import Header from './Header';
import Header from './header/Main';
import Banner from './Banner';
import Landing from './Landing';
import Logout from './Logout';
//import SurveyNew from './surveys/SurveyNew';
import Register from './register/Register';
import TokenGatedForm from './examples/TokenGatedForm';
import TokenGatedContent from './examples/TokenGatedContent';
import TokenGatedCalendar from './calendar/TokenGatedCalendar';
import AddNFTCollection from './addNFT/Collection';
import EditNFTCollection from './editNFT/Collection';
import QuickAddNFTCollection from './quickAddNFT/Main';
import AllNFTsMain from './allNFTs/Main';
import AlertsMain from './alerts/Main';
import SignupMain from './signup/Main';
import CollectionPage from './collectionPage/Main';
import RegisterSuccess from './calendar/RegisterSuccess';

import OnboardStep2 from './onboard/step2TypeOfUse/Main';
import OnboardStep3 from './onboard/step3Upsell/Main';
import OnboardStep4 from './onboard/step4SelectNFTAlerts/Main';
import OnboardStep5 from './onboard/step5Notifications/Main';
import OnboardStep6 from './onboard/step6Complete/Main';

import LeaderboardRedirect from './leaderboard/LeaderboardRedirect';
import LeaderboardWeekly from './leaderboard/weeklyLeaderboard/Main';
import LeaderboardMonthly from './leaderboard/monthlyLeaderboard/Main';
import LeaderboardLifetime from './leaderboard/lifetimeLeaderboard/Main';

import GiveawaysRedirect from './giveaways/GiveawaysRedirect';
import GiveawaysLive from './giveaways/live/Main';
import GiveawaysUpcoming from './giveaways/upcoming/Main';
import GiveawaysEnded from './giveaways/ended/Main';
import GiveawaysRules from './giveaways/rules/Main';

import DashboardHome from './dashboard/home/Main';
import Dashboard from './dashboard/userNFTs/Main';
import DashboardHiddenNFTs from './dashboard/userHiddenNFTs/Main';
import DashboardAllowList from './dashboard/allowLists/Main';
import DashboardWatchlist from './dashboard/watchlist/Main';
import DashboardEntriesHistory from './dashboard/entriesHistory/Main';

import BenefitsUser from './benefits/userBenefits/Main';
import BenefitsAll from './benefits/allBenefits/Main';
import RafflesFeed from './benefits/rafflesFeed/Main';

import Review from './admin/reviewNFT/AdminReview';
import ReviewRaffles from './admin/reviewRaffles/userInput/Main';
import ReviewRafflesAutomated from './admin/reviewRaffles/automated/Main';
import OpenseaReview from './admin/opensea/Main';
import MergeReview from './admin/merge/Main';
import BenefitsReview from './admin/benefits/Main';
import EditBenefit from './admin/edit/benefit/Main';
import RelatedReview from './admin/related/review/Main';
import RelatedApproved from './admin/related/approved/Main';
import PromoCodes from './admin/owner/promoCodes/Main';

import ReviewUpcomingGiveaways from './admin/giveaways/upcoming/Main';
import ReviewEndedGiveaways from './admin/giveaways/ended/Main';
import ReviewNewGiveaways from './admin/giveaways/new/Main';
import ReviewEditGiveaways from './admin/giveaways/edit/Main';

import PassLocation from './landing/PassLocation';
import LandingBenefitAlerts from './landing/BenefitAlerts';
import LandingResearchBetter from './landing/ResearchBetter';
import LandingBenefitsCommunitySentimentInPalmOfYourHand from './landing/BenefitsCommunitySentimentInPalmOfYourHand';

import Mint from './mint/Mint';

import SettingsLinkWallets from './settings/linkWallets/Main';
import SettingsNotifications from './settings/notifications/Main';

import ConfirmLinkedWalletOptions from './confirmLinkedWallet/Main';
import ConfirmLinkedWallet from './confirmLinkedWallet/confirm/Main';
import RejectLinkedWallet from './confirmLinkedWallet/reject/Main';
import UnlinkWallet from './unlinkWallet/Main';
import ConfirmUnlinkWallet from './unlinkWallet/confirm/Main';

import Privacy from './Privacy';
import TermsOfService from './TermsOfService';
import Footer from './Footer';

class App extends Component {

    componentDidMount() {
        // this.props.fetchUser();
        //console.log('DidMount');
        this.props.fetchCurrentEthereumAccount();
    }

    componentDidUpdate() {
        // this.props.wallet.account will not be present in comoponentDidMount()
        if (!this.props.registeredUser.username && this.props.wallet.account) this.props.fetchRegisteredUser(this.props.wallet.account);
        
        const { auth, tokenGatedAccess, alerts } = this.props;
        const staleDataDate = new Date(new Date() - 1 * 60 * 60 * 1000);
        const yesterday = new Date(new Date() - 24 * 60 * 60 * 1000);
        
        if ( this.props.registeredUser.promo_code === null || this.props.registeredUser.promo_end_date < yesterday ) {
            
            if (auth && auth.last_checked_token_contract_address_ts < staleDataDate ) {
                //console.log('Old Token', auth?.token_contract_address)
                //console.log('New Token', tokenGatedAccess.prioritizedAccessToken)

                if (tokenGatedAccess.prioritizedAccessToken) {
                    // This might be useful for the rare event of observing a "token upgrade",
                    // especially if these logs can be seen remotely, if something is not
                    // working for the user and they're reporting a bug.
                    // This may also helpful for testing the upgrade process
                    // console.log('Attempting to refreshTokenContractAddress ...')
                    // console.log('Old Token', auth?.token_contract_address)
                    // console.log('New Token', tokenGatedAccess.prioritizedAccessToken)
                }
                //console.log('DidUpdate');
                this.props.refreshTokenContractAddress()
                this.props.fetchCurrentEthereumAccount()
                this.props.fetchRegisteredUser(this.props.wallet.account);
            }
            else { 
                //console.log('Old Token', auth?.token_contract_address)
                //console.log('New Token', tokenGatedAccess.prioritizedAccessToken)
            }
        }

        const { refresh } = this.props;
        if (auth && refresh.walletTokens) this.props.refreshWalletTokens()
        //console.log(refresh.walletTokens);
        // The expression !refersh.walletTokens in the following line allows
        // for wallet tokens to be refreshed before refreshing memberships
        if (auth && !refresh.walletTokens && refresh.contractMemberships) this.props.refreshContractMemberships()
        //console.log(refresh.walletTokens, refresh.contractMemberships);
        if ( auth ) {
            this.props.fetchAlertsCount();            
        }
    }

    renderAnalytics( id, token ) {
        
        if ( id ) { 
            window.analytics.identify( id, {
                tokenAddress: token
            } );
        }        
    }

    render() {        
        if ( this.props.registeredUser.id ) {
            window.dataLayer.push({
                user_id: this.props.registeredUser.id
            });             
        }        
        if ( this.props.auth && !this.props.registeredUser ) {
            <Redirect to="/logout" />
        }
        //console.log(window.location.pathname);
        //console.log(window.location.href);
        
        return (
            <>
                <BrowserRouter>
                    <ScrollToTop />
                    {/* Render the Redux store keys as props passed here */}
                    {/* <p>{JSON.stringify(this.props.wallet)}</p> */}
                    {/* <p>{JSON.stringify(this.props.registeredUser)}</p> */}  
                    {/* <!-- Google Tag Manager (noscript) --> */}
                    <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-PKHPK3M"
                    height="0" width="0" style={{display:"none", visibility:"hidden"}} title="gtm"></iframe></noscript>                    
                    {/* <!-- End Google Tag Manager (noscript) --> */}
                    <Header />                    
                    
                    <div className="min-vh-100 container-fluid" style={{ backgroundColor: "#140510", color:"rgba(255,255,255,0.8)", paddingBottom:"20px"}}>                        
                            <Banner />
                            {/*<Route exact path="/" component={Landing} />*/}

                            <Route exact path="/" component={LandingBenefitsCommunitySentimentInPalmOfYourHand} />
                            <Route exact path="/landing-benefit-alerts" component={LandingBenefitAlerts} />
                            <Route exact path="/landing-research-better" component={LandingResearchBetter} />
                            <Route exact path="/landing-benefits-community-sentiment-in-palm" component={LandingBenefitsCommunitySentimentInPalmOfYourHand} />

                            <Route exact path="/" component={SignupMain} />
                            <Route exact path="/landing-benefit-alerts" component={SignupMain} />
                            <Route exact path="/landing-research-better" component={SignupMain} />
                            <Route exact path="/landing-benefits-community-sentiment-in-palm" component={SignupMain} />
                            <Route exact path="/mint" component={Mint} />

                            <Route exact path="/giveaways" component={GiveawaysRedirect} />
                            <Route exact path="/giveaways/live" component={GiveawaysLive} />
                            <Route exact path="/giveaways/upcoming" component={GiveawaysUpcoming} />
                            <Route exact path="/giveaways/ended" component={GiveawaysEnded} />
                            <Route exact path="/giveaways/rules" component={GiveawaysRules} />
                            
                            <Route exact path="/all-nfts" component={AllNFTsMain} />
                            <Route exact path="/all-nfts/:page" component={AllNFTsMain} />
                            <Route exact path="/alerts" component={AlertsMain} />
                            <Route exact path="/alerts/:page" component={AlertsMain} />
                            <Route exact path="/calendar" component={TokenGatedCalendar} />
                            <Route exact path="/calendar/:page" component={TokenGatedCalendar} />
                            <Route exact path="/collection/:nft_collection_value" component={CollectionPage} />                            
                            <Route exact path="/logout" component={Logout} />
                            <Route exact path="/token-gated-content" component={TokenGatedContent} />
                            <Route path="/token-gated-form" component={TokenGatedForm} />                            
                            <Route exact path="/add/nft/quick" component={QuickAddNFTCollection} />
                            <Route exact path="/add/nft" component={AddNFTCollection} />
                            <Route exact path="/edit/nft/:nft_collection_id" component={EditNFTCollection} />
                            <Route exact path="/edit/nft" component={EditNFTCollection} />                            
                            <Route exact path="/signup" component={SignupMain} />

                            <Route exact path="/register" component={Register} />
                            <Route exact path="/onboard/step-2" component={OnboardStep2} />
                            <Route exact path="/onboard/step-3" component={OnboardStep3} />
                            <Route exact path="/onboard/step-4" component={OnboardStep4} />
                            <Route exact path="/onboard/step-4/:page" component={OnboardStep4} />
                            <Route exact path="/onboard/step-5" component={OnboardStep5} />
                            <Route exact path="/onboard/step-6" component={OnboardStep6} />
                            <Route exact path="/register-success" component={RegisterSuccess} />                            
                            
                            <Route exact path="/leaderboard/" component={LeaderboardRedirect} />
                            <Route exact path="/leaderboard/weekly" component={LeaderboardWeekly} />
                            <Route exact path="/leaderboard/weekly/:page" component={LeaderboardWeekly} />
                            <Route exact path="/leaderboard/monthly" component={LeaderboardMonthly} />
                            <Route exact path="/leaderboard/monthly/:page" component={LeaderboardMonthly} />
                            <Route exact path="/leaderboard/lifetime" component={LeaderboardLifetime} />
                            <Route exact path="/leaderboard/lifetime/:page" component={LeaderboardLifetime} />                            

                            <Route exact path="/dashboard/home" component={DashboardHome} />
                            <Route exact path="/dashboard/user-nfts/:page" component={Dashboard} />
                            <Route exact path="/dashboard/user-nfts" component={Dashboard} />
                            <Route exact path="/dashboard/user-hidden-nfts/:page" component={DashboardHiddenNFTs} />
                            <Route exact path="/dashboard/user-hidden-nfts" component={DashboardHiddenNFTs} />
                            <Route exact path="/dashboard/allow-lists/:page" component={DashboardAllowList} />
                            <Route exact path="/dashboard/allow-lists" component={DashboardAllowList} />
                            <Route exact path="/dashboard/watchlist/:page" component={DashboardWatchlist} />
                            <Route exact path="/dashboard/watchlist" component={DashboardWatchlist} />
                            <Route exact path="/dashboard/entries-history" component={DashboardEntriesHistory} />                            

                            <Route exact path="/benefits" component={BenefitsUser} />
                            <Route exact path="/all-benefits" component={BenefitsAll} />
                            <Route exact path="/raffles-feed" component={RafflesFeed} />

                            <Route exact path="/admin/review" component={Review} />
                            <Route exact path="/admin/review/:page" component={Review} />                            
                            <Route exact path="/admin/review-raffles" component={ReviewRaffles} />
                            <Route exact path="/admin/review-raffles/:page" component={ReviewRaffles} />
                            <Route exact path="/admin/review-automated-raffles" component={ReviewRafflesAutomated} />
                            <Route exact path="/admin/opensea" component={OpenseaReview} />
                            <Route exact path="/admin/opensea/:page" component={OpenseaReview} />
                            <Route exact path="/admin/merge" component={MergeReview} />   
                            <Route exact path="/admin/benefits" component={BenefitsReview} />
                            <Route exact path="/admin/benefits/:page" component={BenefitsReview} />   
                            <Route exact path="/admin/edit/benefit/:benefit_id" component={EditBenefit} />   
                            <Route exact path="/admin/related" component={RelatedReview} />
                            <Route exact path="/admin/related/:page" component={RelatedReview} />   
                            <Route exact path="/admin/related-approved" component={RelatedApproved} />
                            <Route exact path="/admin/related-approved/:page" component={RelatedApproved} />                      
                            <Route exact path="/admin/promo-codes" component={PromoCodes} />
                            <Route exact path="/admin/promo-codes/:page" component={PromoCodes} />  

                            <Route exact path="/admin/giveaways/upcoming" component={ReviewUpcomingGiveaways} />
                            <Route exact path="/admin/giveaways/upcoming/:page" component={ReviewUpcomingGiveaways} />
                            <Route exact path="/admin/giveaways/ended" component={ReviewEndedGiveaways} />
                            <Route exact path="/admin/giveaways/ended:page" component={ReviewEndedGiveaways} />
                            <Route exact path="/admin/giveaways/new" component={ReviewNewGiveaways} />
                            <Route exact path="/admin/giveaways/edit/:giveawayid" component={ReviewEditGiveaways} />
                            
                            <Route exact path="/settings/link-wallets" component={SettingsLinkWallets} />
                            <Route exact path="/settings/link-wallets/:page" component={SettingsLinkWallets} />
                            <Route exact path="/settings/notifications" component={SettingsNotifications} />  

                            <Route exact path="/confirm/link-wallet" component={ConfirmLinkedWalletOptions} />
                            <Route exact path="/confirm/link-wallet/confirm" component={ConfirmLinkedWallet} />
                            <Route exact path="/confirm/link-wallet/reject" component={RejectLinkedWallet} />

                            <Route exact path="/unlink-wallet/:linkedWalletId" component={UnlinkWallet} />
                            <Route exact path="/confirm/unlink-wallet/:linkedWalletId" component={ConfirmUnlinkWallet} />                            

                            <Route path="/privacy" component={Privacy} />
                            <Route path="/tos" component={TermsOfService} />
                            {/* <Route path="/surveys/new" component={SurveyNew} /> */}
                    </div>
                    
                    {this.renderAnalytics( this.props.registeredUser.id, this.props.registeredUser.token_contract_address )}
                    <Footer />
                </BrowserRouter>
            </>
        );
    }
};

function mapStateToProps({ auth, wallet, registeredUser, tokenGatedAccess, refresh, alerts }) {
    return { auth, wallet, registeredUser, tokenGatedAccess, refresh, alerts };
}

export default connect(mapStateToProps, actions)(App);
