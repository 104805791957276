import { SiweMessage } from 'siwe';
import axios from 'axios';

const getNonce = async () => {
    try {
        const response = await axios.post("/auth/ethereum/challenge", {
            validateStatus: function (status) {
                console.log(status);
                return status < 500; // Resolve only if the status code is less than 500
            }
        });
        return response.data?.nonce;
    } catch (error) {
        console.error("Failed to get challenge:");
        console.error(error);
    }
}

const siweMessage = (address, nonce) => {
    return new SiweMessage({
        domain: window.location.host,
        address,
        statement: 'Sign in to Future NFT Mints',
        uri: window.location.origin,
        version: '1',
        chainId: '1',
        nonce
    }).prepareMessage();
}

const promptSignature = async (signer, message) => {
    try {
        return await signer.signMessage(message);
    } catch (error) {
        console.error("Caught error in promptSignature:");
        console.error(error);
    }
}

const signInWithMessage = async (message, signature) => {
    try {
        const response = await axios.post("/auth/ethereum", { message, signature }, {
            validateStatus: function (status) {                
                return status < 500; // Resolve only if the status code is less than 500
            }
        });
        return response.data
    } catch (error) {
        console.error("Failed to verify signature:");
        console.error(error);
    }
}

export { getNonce, siweMessage, promptSignature, signInWithMessage }
