import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';

import { connect } from 'react-redux';
import * as actions from '../actions';

const Landing = ({ wallet, registeredUser, fetchRegisteredUser }) => {

    return ( <><Redirect to="/dashboard/home" /></>
        /*
        registeredUser.username ? <Redirect to="/dashboard/home" /> : 
        (account) ? <Redirect to="/register" /> : 
        <>
        <div className="row">

            <div className="col-sm-6" style={{ textAlign: 'center', backgroundImage: "linear-gradient(#000000 10%, #A11692)"}}>
                <div style={{ paddingTop :"150px", paddingBottom :"150px"}}>
                    <img
                    alt=""
                    src="/images/future-nft-mints-logo.png"
                    height="100"
                    className="d-inline-block align-top"
                    />                    
                </div>
            </div>
            <div className="col-sm-6" style={{ textAlign: "left", backgroundColor:"#000000", padding: "50px"}}>
                <div style={{ paddingTop:"75px", fontSize:"3em", color:"#ffffff"}}>
                    Get Started
                </div>
                <div style={{fontSize:"1.5em", color:"#ffffff"}}>
                    Connect your wallet
                </div>
                <div style={{fontSize:"1em", color:"#ffffff", paddingBottom:"400px"}}>
                    Connecting your wallet will enable you to access our token-gated research and analyses as well as claim your Future NFT Mints' daily newsletter subscription.
                    <br /><br />
                    Don't have a Future NFT Mints NFT? <a href="https://mint.futurenftmints.com" style={{color:"#ffffff"}} target="_BLANK" rel="noreferrer">Learn more about our hodler benefits, roadmap, team, and mint one</a>.
                </div>
            </div>
        </div>


        </>
        */
    );
};

function mapStateToProps({ wallet, registeredUser }) {
    return { wallet, registeredUser };
}

export default connect(mapStateToProps, actions)(Landing);
